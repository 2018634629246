import React from "react";
import { RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from "../../../regs";
const BankDetailForm = React.lazy(() => import('./BankDetailForm'))

class BanksRD extends RegistryDescriptor {
    name: ApplicationUnit = 'banks'
    gridEndpoint = 'banks'
    docEndpoint = 'banks'
    selector = {
        endpoint: 'banks',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
    }
    columns =  [
        {name: 'code'},
        {name: 'name'},
        {name: 'swift'}
    ]
    defaultDocument = () => {status: 0}

    getDetailForm = (docPath: string) =>  <BankDetailForm docPath={docPath} />
}

export const banks = new BanksRD()
