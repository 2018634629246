import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { SETUP_LOCALE } from "../app/const"

import messages_en from './messages.en'
import messages_et from "./messages.et"
import messages_ru from "./messages.ru"

import messages_grid_et from "./messages_grid.et"

export type LanguageType = 'et' | 'en' | 'ru'

export const languages: LanguageType[] = [
    "et", "en", "ru"
]

export const DEFAULT_LOCALE: LanguageType = "et"

export const getLocale = () => {
    return (useSelector(state => (state as RootState).localSetup[SETUP_LOCALE]) || DEFAULT_LOCALE) as LanguageType
}

export const messages = {
    en: messages_en,
    et: messages_et,
    ru: messages_ru
}

export const gridLocale = {
    et: messages_grid_et,
    en: undefined,
    ru: undefined
}

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';