const messages = {
    ' ': ' ',   // space is used as a placeholder for empty translations
    btn_new: "Добавить новый",
    btn_save: "Сохранить",
    btn_delete: "Удалить",
    btn_print: "Печать",
    btn_undo: "Отменить",
    btn_login: "Войти",
    btn_sign_in_google: "Войти с аккаунтом Google",
    btn_cancel: 'Отменить',
    btn_refresh: 'Обновить',
    btn_remove: 'Удалить',
    btn_create: 'Создать',
    btn_dismiss: 'Закрыть',
    btn_back: 'Назад',
    btn_yes: 'Да',
    btn_no: 'Нет',
    btn_add_user: 'Добавить пользователя',
    btn_invite_new_user: 'Пригласить нового пользователя',
    btn_copy: 'Открыть копию',
    btn_ok: 'OK',
    btn_download: 'Скачать',
    btn_email: 'Отправить по электронной почте',
    btn_setup: 'Настройки',
    btn_go_home: 'На главную',
    info: 'Информация',
    app_title: "Profit",
    code: "Код",
    name: "Имя",
    memo: "Дополнительная информация",
    frontpage: "Главная страница",
    companies: "Компании",
    accounts: "План счетов",
    banks: "Банки",
    purchaseinvoices: "Счета-фактуры на покупку",
    currencies: "Валюты",
    warehouses: "Склады",
    units: "Единицы измерения",
    objects: "Объекты",
    articles: "Товары",
    reg_code: "Рег. код",
    vat_code: "Код НДС",
    type_id: "Тип",
    logout: "Выйти",
    nr: "№",
    date: "Дата",
    document_date: "Дата документа",
    companyCode: "Код компании",
    companyName: "Название компании",
    total: "Всего",
    currency: "Валюта",
    currencyRateLabel: "Курс",
    amount_to_pay: "К оплате",
    statusLabel: "Статус",
    waybillNumbers: "Номера накладных",
    summary_rows: "Суммарно по строкам",
    summary_obj: "Суммарно по объектам",
    payments: "Платежи",
    // grid_memo: "Lisainfo",
    created_datetime: "Создано",
    creator: "Создатель",
    modified_datetime: "Изменено",
    modifier: "Изменитель",
    attsCnt: "Вложения",
    edit_sales_title: "Продажа",
    edit_address_title: "Адрес",
    edit_contacts_title: "Контактные данные",
    edit_primary_title: "Основные данные",
    edit_setup_title: "Настройки",
    setup_general_title: "Общие настройки",
    grid_companycontacts: "Контакты",
    grid_bank_accounts: "Банковские счета",
    grid_salesprices: 'Цены продажи',
    grid_purchaseprices: 'Цены покупки',

    grid_purchaseinvoice_rows: 'Товары',

    company_id: 'Компания',
    supplierCode: 'Код поставщика',
    purchasePrice: 'Цена покупки',
    pricelist_id: 'Прайс-лист',
    customer_account_id: "Счет клиента",
    supplier_account_id: "Счет поставщика",
    customer: "Клиент",
    supplier: "Поставщик",
    edit_attributes_title: "Дополнительная информация",
    phone: "Телефон",
    email: "Электронная почта",
    fax: "Факс",
    contact_person: "Контактное лицо",
    legal_address_street: "Улица",
    legal_address_city: "Город",
    legal_address_country_id: "Страна",
    legal_address_postal_code: "Почтовый индекс",
    postal_address_street: "Улица",
    postal_address_city: "Город",
    postal_address_country_id: "Страна",
    postal_address_postal_code: "Почтовый индекс",
    legal_address_title: "Юридический адрес",
    postal_address_title: "Почтовый адрес",
    countries: "Страны",
    prefix: "Префикс",

    password: 'Пароль',
    eu_code: "Код ЕС",
    account_group_id: "Группа",

    "account.group.activa": "Aktiva",   //TODO
    "account.group.passiva": "Passiva",
    "account.group.income": "Tulu",
    "account.group.expense": "Kulu",

    "account.type.detail": "Детально",
    "account.type.summary": "Общее",
    "login.company": "Компания",
    salesinvoices: "Счета на продажу",
    buyer_id: "Покупатель",
    due_period: "Срок оплаты",
    due_date_alt: "Срок оплаты",
    reference_number: 'Референсный номер',
    wh_id: 'Склад',
    interest: "Процент пени",
    currency_Id: "Валюта",
    rate: 'Курс',
    drate: 'Курс',
    nrpefix: 'Префикс',
    nrsuffix: 'Суффикс',
    sum: 'Сумма',
    summaryHeader: 'Итог',
    article_id: 'Товар',
    article_name: 'Наименование товара',
    quantity: 'Количество',
    price: 'Цена',
    rounding: 'Округление',
    nrprefix: 'Префикс',
    IBAN: 'IBAN',
    alert_document_loading: 'Загрузка документа',
    alert_document_failed: 'Ошибка',
    alert_document_saved: 'Документ сохранен',
    alert_document_saving: 'Сохранение документа',
    unit_id: 'Единица измерения',
    vats: 'Ставки НДС',
    vat_id: 'НДС%',
    vat_total: 'НДС',
    total_vat: 'Всего НДС',
    correction: 'Корректировка',
    vat_return: 'Возвращаемый НДС',
    rowTotal: 'Итого по строке',
    obj_id: 'Объект',
    msg_delete_selected_rows: 'Удалить выбранные строки?',
    question: 'Вопрос',
    msg_delete_document: 'Удалить документ?',
    'Field code should be set when adding new entry': 'Код должен быть установлен при добавлении новой записи',
    'Not unique': 'Не уникально',
    'Validation errors': 'Ошибки валидации',
    'Required': 'Обязательно',
    'Currency rate must not be 0': 'Курс валюты не должен быть 0',
    'VAT applied': 'НДС применен',
    totals_vat_applied_to: 'НДС применен {vat_percent}%',
    totals_vat_total: 'Всего НДС {vat_percent}%',
    'total without vat': 'Всего без НДС',
    'total with vat': 'Всего с НДС',
    bank_id: 'Банк',
    'contact': 'Контакт',
    'payment_period': 'Срок оплаты',
    'unit': 'Единица измерения',
    'taxed with': 'Облагается налогом',
    'bank.requisits': 'Банковские реквизиты',
    'reg.code': 'Рег. код',
    'vat.code': 'Код НДС',
    'payer': 'Плательщик',
    invoice_no: 'Номер счета',

    'Failed to fetch': 'Не удалось получить данные',
    'buyer': 'Покупатель',
    unsaved_changes_prompt: 'Выйти без сохранения?',
    'register': 'Регистрация',
    'my enterprises': 'Мои предприятия',
    'new enterprise': 'Новое предприятие',
    'storage dashboard': 'Панель управления хранилищем',
    debug: 'Отладка',
    discount: '-%',
    'company data': 'Данные компании',
    company_data_tooltip: 'Просмотр/изменение данных компании',
    not_found_add: 'Не найдено, добавить',
    'companyExtras': 'Дополнительная информация о компании',
    user_menu: 'Настройки аккаунта',
    'settings': 'Настройки',
    refresh: 'Обновить',
    'billing data': 'Платежные данные',
    'goto enterprise': 'Открыть предприятие',
    'invite user': 'Пригласить пользователя',
    'enterprise name': 'Название предприятия',
    'enterprise type': 'Тип',
    'default estonian oy': 'По умолчанию Эстонская OÜ',
    'empty database': 'Пустая база данных',
    'database creation error': 'Ошибка создания базы данных',
    'unknown error': 'Неизвестная ошибка',
    'database creation error message': 'Мы сообщили об этом технической поддержке. Пожалуйста, попробуйте позже.',
    'database creation in progress': 'Создание базы данных',
    status: 'Статус',
    waiting: 'Ожидание',
    creating: 'Создание',
    'tabase successfully created': 'База данных успешно создана',
    type_ext_id: 'Тип',
    'article.typeExt.expense': 'Статья расходов',
    'article.typeExt.product': 'Товар склада',
    financial_block_title: 'Финансы',
    sales_block_title: 'Продажи',
    barcode: 'Штрих-код',
    pricelists: 'Прайс-листы',
    articlegroups: 'Группы товаров',
    account_id: 'Счет',
    purchase_account_id: 'Счет покупки',
    purchase_vat_id: 'Ставка НДС при покупке',
    parent_id: 'Родительская группа',
    articlegroup_id: 'Группа товаров',
    sidebar_show: 'Показать боковую панель',
    sidebar_hide: 'Скрыть боковую панель',

    salesinvoices_due_report: 'Отчет о неоплаченных счетах',
    sales_report_title: 'Отчет о продажах {period_start} - {period_end}',
    sales_report: 'Отчет о продажах',
    period_start: 'Начало периода',
    period_end: 'Конец периода',
    payer_id: 'Плательщик',
    due_date: 'Дата оплаты',
    total_with_vat: 'Сумма с НДС',
    invoice_type_general: 'Обычный счет',
    invoice_type_cash: 'Счет за наличные',
    invoice_type_card: 'Счет за карту',
    articlegroup: 'Группа товаров',
    types: 'Типы',
    sort_order: 'Сортировка',

    'doc_date, buyer_name, doc_nr': 'Дата, покупатель, номер',
    'buyer_name, doc_date, doc_nr': 'Номер, дата, покупатель',
    'doc_date, doc_nr, buyer_name': 'Дата, номер, покупатель',

    setup_additional_title: 'Дополнительные настройки',

    'FIRMA.NAME': 'Название компании',
    'FIRMA.KOOD': 'Регистрационный код',
    'FIRMA.KKNR': 'Номер налогоплательщика НДС',
    'FIRMA.AADRESS': 'Адрес',
    'FIRMA.TELEFON': 'Телефон',
    'FIRMA.EMAIL': 'Электронная почта',
    'FIRMA.VALUUTA': 'Базовая валюта',
    'FIRMA.VALUUTA2': 'Вторая базовая валюта',
    'DEF.FIRMAD.HINNAKIRIID': 'Базовый прайс-лист',
    'DEF.FIRMAD.MAKSETAHTAEG': 'Срок оплаты по умолчанию',
    'DEF.FIRMAD.HANKIJAKONTOID': 'Счет поставщика',
    'DEF.FIRMAD.KLIENDIKONTOID': 'Счет клиента',
    'DEF.FIRMAD.KMLIIKID': 'Тип НДС',
    'DEF.FIRMAD.KMDINF': 'Информация о НДС',

    'EMAIL.SMTP.SERVER': 'Почтовый сервер (SMTP)',
    'EMAIL.SMTP.PORT': 'Порт почтового сервера',
    'EMAIL.SMTP.UID': 'Пользователь почтового сервера',
    'EMAIL.SMTP.PWD': 'Пароль почтового сервера',
    'EMAIL.SMTP.TLS': 'Настройки безопасности',
    'EMAIL.USESMTP': 'Использовать SMTP',
    'EMAIL.SMTP.BCC': 'Всегда отправлять скрытую копию (BCC)',
    'EMAIL.SMTP.SIGNATURE': 'Подпись электронного письма',
    'EMAIL.SUBJECT.DEFAULT': 'Тема электронного письма по умолчанию',

    'FIRMA.RIIK': 'Страна',
    report_run_pdf: 'Предварительный просмотр',
    report_run_pdf_tooltip: 'Предварительный просмотр отчета в формате PDF',
    article: 'Товар',
    object: 'Объект',
    vat: 'Ставка НДС',
    article_group: 'Группа товаров',
    'currency selector': 'Выбор валюты',
    'interest editor': 'Изменение процентной ставки',
    'unit visible': 'Единица измерения видима',
    'unit editable': 'Единица измерения редактируема',
    'vat visible': 'НДС видим',
    'vat editable': 'НДС редактируем',
    'discount visible': 'Столбец скидки',
    'total w/o vat visible': 'Сумма без НДС',
    'total vat visible': 'Сумма НДС',
    'total with vat visible': 'Сумма с НДС',
    'show revenues in totals': 'Показывать доходы в итогах',
    obj_visible: 'Столбец объекта',
    invoice_nr_visible: 'Номер счета',
    due_date_visible: 'Срок оплаты',
    interest_visible: 'Столбец процентов',
    invoice_nr: 'Номер счета',

    colorscheme_light: 'Светлая цветовая схема',
    colorscheme_dark: 'Темная цветовая схема',
    colorscheme_system: 'Системная цветовая схема',
    density_default: 'Плотность по умолчанию',
    density_comfortable: 'Комфортная плотность',
    density_compact: 'Компактная плотность',

    msg_error_loading_links: 'Ошибка загрузки ссылок',

    doc_date: 'Дата',
    buyer_name: 'Покупатель',
    payer_name: 'Плательщик',
    currency_code: 'Валюта',
    currency_rate: 'Курс',
    waybills: 'Накладные',
    to_pay: 'К оплате',
    summaryrows: 'Информация по строкам',
    locked_icon: '🔒',
    attachment_icon: '📎',
    label_icon: '🏷️',
    emailed_status_icon: '📧',
    sender_login: 'Создатель',
    grid_memo: 'Дополнительное поле',
    d_created: 'Создано',
    d_modified: 'Изменено',
    mdatts: 'Вложения',
    labels: 'Метки',

    setup_users_title: 'Пользователи',
    setup_companies_title: 'Компании',
    setup_company_title: 'Настройки компании',
    setup_printforms_title: 'Формы печати',
    setup_dangerous_title: 'Опасные настройки',
    setup_dangerous_warning: 'Следующие настройки потенциально опасны. Убедитесь, что вы уверены, что хотите продолжить.',
    setup_tech_title: 'Технические настройки',
    setup_users_no_database: 'База данных отсутствует',
    title_add_new_user: 'Добавить нового пользователя',
    select_new_user_name: 'Выберите имя нового пользователя',
    tooltip_invite_new_user: 'Не можете найти пользователя? Пригласите нового пользователя по электронной почте',

    email_invite_new_user_subject: 'Приглашаю вас использовать программное обеспечение для бухгалтерии Profit',
    email_invite_new_user_body: 'TODO здесь будет содержание письма',
    msg_remove_user: 'Вы уверены, что хотите удалить пользователя?',
    msg_hello: 'Привет {name}',
    msg_no_name: 'Пожалуйста, введите имя, под которым вас будут видеть другие пользователи. \nБез имени вас нельзя пригласить или найти.',
    lbl_enter_your_user_name: 'Введите ваше имя',
    msg_user_name_default: 'Имя пользователя должно быть уникальным, состоять минимум из 3 символов и может содержать только буквы, цифры, точки и подчеркивания.',
    msg_user_name_too_short: 'Имя пользователя должно быть длиной не менее 3 символов.',
    msg_user_name_too_long: 'Имя пользователя не должно превышать 20 символов.',
    msg_uniqueness_checked_on_save: 'Уникальность проверяется при сохранении.',
    msg_not_shared: 'База данных компании не поделена с кем-либо.',
    btn_edit_name: 'Изменить имя',
    btn_cancel_edit_name: 'Отменить изменение имени',

    db_status_ready: 'готово',
    db_status_waiting: 'в ожидании создания',
    db_status_creating: 'создается',
    db_status_error: 'ошибка',
    db_status_updating: 'обновляется',

    msg_loading_databases_list: 'Загрузка списка баз данных',
    msg_no_databases: 'Компании не найдены, создайте новую или подождите, пока кто-то поделится с вами данными своей компании.',
    msg_cant_create_no_name: 'Невозможно создать новые базы данных, так как отсутствует имя пользователя.',
    btn_lock: 'Заблокировать',
    btn_unlock: 'Разблокировать',
    btn_filter: 'Фильтр',
    filter: 'Фильтр',

    doc_date_from: 'Начало периода',
    doc_date_until: 'Конец периода',
    btn_close: 'Закрыть',
    total_from: 'Сумма от',
    total_until: 'Сумма до',
    object_id: 'Объект',
    currency_id: 'Валюта',
    filter_active: 'Фильтр активен',
    selected: 'строка выбрана',
    payer_selector: 'Выбор плательщика',
    btn_register: 'Создать аккаунт',
    version: 'Версия {version}',
    db_status_archiving: 'Архивация',
    msg_archive_db: 'Вы уверены, что хотите удалить базу данных?',
    btn_delete_db: 'Удалить базу данных',
    msg_database_archiving_long: 'База данных помечена для удаления.',
    title_database_archiving: 'База данных удаляется',
    menu_archived_visible: 'Показать удаленные базы данных',
    menu_archiving_visible: 'Показать базы данных, помеченные для удаления',
    title_database_archived: 'Удаленная база данных',
    msg_database_archived_long: 'База данных удалена.',
    msg_setup_users_shared_database: 'Вы не являетесь владельцем этой базы данных',
    msg_report_error: 'Ошибка при создании отчета:{br}{error}',


    shorthelp_salesinvoices_nr: 'Номер счета-фактуры.{br}Номер генерируется автоматически, его можно изменить',
    shorthelp_salesinvoices_salesinvoicerows_vat_id: 'Ставка НДС.{br}Ставка НДС, используемая в счете',
    shorthelp_salesinvoices_memo: 'Дополнительная информация о счете-фактуре.{br}Не отображается в счете',
    shorthelp_salesinvoices_salesinvoicerows_discount: 'Скидка.{br}Процент скидки по строке',
    shorthelp_salesinvoices_salesinvoicerows_price: 'Цена.{br}Цена товара без НДС, программа может предложить цену продажи в соответствии с прайс-листом покупателя',
    shorthelp_salesinvoices_salesinvoicerows_unit_id: 'Единица измерения.{br}Единица измерения товара',
    shorthelp_salesinvoices_salesinvoicerows_quantity: 'Количество.{br}Количество товара, по умолчанию 1',
    shorthelp_salesinvoices_salesinvoicerows_article_name: 'Наименование товара.{br}Наименование товара, по умолчанию берется с карточки товара.{br}Название можно изменить',
    shorthelp_salesinvoices_salesinvoicerows_article_id: 'Товар.{br}Выбор товара',
    shorthelp_row__sel: 'Выберите строку.{br}Можно удалить выбранные строки',
    shorthelp_salesinvoices_due_date: 'Дата оплаты.{br}Зависит от даты счета и срока оплаты.{br}Для изменения измените срок оплаты',
    shorthelp_salesinvoices_due_period: 'Срок оплаты.{br}Срок оплаты в днях с даты выставления счета.{br}Значение по умолчанию берется из данных покупателя',
    shorthelp_salesinvoices_date: 'Дата счета.{br}Дата выставления счета',
    shorthelp_salesinvoices_buyer_id: 'Выбор покупателя.{br}В выборе участвуют активные клиенты из списка компаний.{br}Если покупателя нет, его можно добавить.{br}Для этого введите имя покупателя и нажмите "Добавить ..." в выпадающем меню{br}"..." кнопка позволяет просмотреть или изменить данные покупателя.',

    shorthelp_salesinvoices_filter_doc_date_from: 'Начало фильтруемого периода.{br}Отображаются счета, дата которых больше или равна',
    shorthelp_salesinvoices_filter_doc_date_until: 'Конец фильтруемого периода.{br}Отображаются счета, дата которых меньше или равна',
    shorthelp_salesinvoices_filter_total_from: 'Сумма от.{br}Отображаются счета, сумма которых больше или равна',
    shorthelp_salesinvoices_filter_total_until: 'Сумма до.{br}Отображаются счета, сумма которых меньше или равна',
    shorthelp_salesinvoices_filter_article_id: 'Фильтр товара.{br}Отображаются счета, в которых есть выбранный товар',
    shorthelp_salesinvoices_filter_object_id: 'Фильтр объекта.{br}Отображаются счета, в которых есть выбранный объект',
    shorthelp_salesinvoices_filter_buyer_id: 'Фильтр покупателя.{br}Отображаются счета выбранного покупателя',
    shorthelp_salesinvoices_filter_payer_id: 'Фильтр плательщика.{br}Отображаются счета выбранного плательщика',
    shorthelp_salesinvoices_filter_currency_id: 'Фильтр валюты.{br}Отображаются счета в выбранной валюте',
    shorthelp_salesinvoices_filter_active: 'Фильтр работает только если этот флажок включен',
    shorthelp_sales_report_period_start: 'Начало периода отчета',
    shorthelp_sales_report_period_end: 'Конец периода отчета',
    shorthelp_sales_report_buyer_id: 'Если выбрано, в отчете будут только счета выбранного покупателя',
    shorthelp_sales_report_payer_id: 'Если выбрано, в отчете будут только счета выбранного плательщика',
    shorthelp_sales_report_supplier_id: 'Если выбрано, в отчете будут только счета с товарами выбранного поставщика',
    shorthelp_sales_report_article_id: 'Если выбрано, в отчете будут только счета с выбранным товаром',
    shorthelp_sales_report_object_id: 'Если выбрано, в отчете будут только счета с выбранным объектом',
    shorthelp_sales_report_vat_id: 'Если выбрано, в отчете будут только счета с выбранным НДС',
    shorthelp_sales_report_grp_id: 'Если выбрано, в отчете будут только счета, содержащие товары из выбранной группы товаров',
    shorthelp_sales_report_type_general: 'Показывать переводные счета{br}Если все флажки типа счета сняты, будут отображаться все счета',
    shorthelp_sales_report_type_cash: 'Показывать наличные счета{br}Если все флажки типа счета сняты, будут отображаться все счета',
    shorthelp_sales_report_type_card: 'Показывать счета с оплатой картой{br}Если все флажки типа счета сняты, будут отображаться все счета',
    shorthelp_sales_report_sort_order: 'Выбор порядка счетов',

    shorthelp_companies_name: 'Название компании',
    shorthelp_companies_code: 'Код компании{br}Уникальный код, используемый внутри программы, например, в выборе компаний.',
    shorthelp_companies_reg_code: 'Регистрационный код{br}Заполняется автоматически, если информация о компании получена из торгового реестра',
    shorthelp_companies_vat_code: 'Номер налогоплательщика НДС{br}Заполняется автоматически, если информация о компании получена из торгового реестра',
    shorthelp_companies_supplier: 'Является поставщиком{br}Если флажок установлен, то это поставщик{br}Поставщики появляются в выборе компании в счете на покупку, заказе на покупку и т.д.',
    shorthelp_companies_customer: 'Является клиентом{br}Если флажок установлен, то это клиент{br}Клиенты появляются в выборе компании в счете на продажу, заказе на продажу и т.д.',

    shorthelp_companies_payment_period: 'Срок оплаты{br}Срок оплаты в днях с даты выставления счета.{br}По умолчанию применяется к счету.',
    shorthelp_companies_interest: 'Процент пени{br}Применяется к счету на продажу',
    shorthelp_companies_currency_id: 'Предпочтительная валюта для расчетов с этим партнером{br}По умолчанию применяется к счету на продажу',

    shorthelp_companies_legal_address_street: 'Юридический адрес: улица, дом, квартира{br}например, Киви 21-1.{br}Заполняется автоматически, если информация о компании получена из торгового реестра',
    shorthelp_companies_legal_address_city: 'Юридический адрес: город{br}например, город Тарту, город Тарту, Тартуская область{br}Заполняется автоматически, если информация о компании получена из торгового реестра',
    shorthelp_companies_legal_address_postal_code: 'Юридический адрес: почтовый индекс{br}например, 12345{br}Заполняется автоматически, если информация о компании получена из торгового реестра',
    shorthelp_companies_legal_address_country_id: 'Юридический адрес: страна{br}например, Эстония',

    shorthelp_companies_postal_address_street: 'Почтовый адрес: улица, дом, квартира{br}например, Киви 21-1',
    shorthelp_companies_postal_address_city: 'Почтовый адрес: город{br}например, город Тарту, город Тарту, Тартуская область',
    shorthelp_companies_postal_address_postal_code: 'Почтовый адрес: почтовый индекс{br}например, 12345',
    shorthelp_companies_postal_address_country_id: 'Почтовый адрес: страна{br}например, Эстония',
    shorthelp_companies_phone: 'Номер телефона{br}например, +372 1234567',
    shorthelp_companies_email: 'Адрес электронной почты{br}например, info@intellisoft.ee',
    shorthelp_companies_contact_person: 'Данные основного контактного лица{br}см. ниже список контактов',
    shorthelp_companies_fax: 'Номер факса{br}например, +372 1234567',
    shorthelp_companies_customer_account_id: 'Номер счета клиента в финансовом модуле{br}Значение по умолчанию берется из общих настроек',
    shorthelp_companies_supplier_account_id: 'Номер счета поставщика в финансовом модуле{br}Значение по умолчанию берется из общих настроек',
    shorthelp_companies_companycontacts_prefix: 'Префикс контакта{br}например, Г-н, Г-жа, Д-р, Проф',
    shorthelp_companies_companycontacts_contact: 'Имя контакта{br}например, Иван Иванов',
    shorthelp_companies_companycontacts_phone: 'Телефонный номер контакта{br}например, +372 1234567',
    shorthelp_companies_companycontacts_email: 'Адрес электронной почты контакта',
    shorthelp_companies_companycontacts_memo: 'Краткая дополнительная информация, связанная с контактом',
    shorthelp_companies_bank_accounts_bank_id: 'Выбор банка',
    shorthelp_companies_bank_accounts_IBAN: 'Банковский счет, IBAN',
    shorthelp_companies_bank_accounts_memo: 'Дополнительная информация о банковском счете',

    shorthelp_articles_code: 'Код товара{br}Уникальный код, используемый внутри программы, например, в выборе товаров.',
    shorthelp_articles_name: 'Наименование товара{br}',
    shorthelp_articles_type_ext_id: 'Тип товара{br}Тип товара определяет, как товар обрабатывается{br}например, в выборе товаров, учете на складе, продажных счетах и т.д.',
    shorthelp_articles_unit_id: 'Единица измерения', shorthelp_articles_articlegroup_id: 'Kauba grupp{br}Kaubagrupi andmetest võivad tulla kauba vaikimisi konto ja maksuseadistused',
    shorthelp_articles_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_articles_barcode: 'Штрих-код или EAN-код товара',
    shorthelp_articles_salesprices_pricelist_id: 'Название прайс-листа',
    shorthelp_articles_salesprices_price: 'Цена',
    shorthelp_articles_salesprices_discount: 'Процент скидки',
    shorthelp_articles_purchaseprices_company_id: 'Поставщик',
    shorthelp_articles_purchaseprices_supplierCode: 'Код товара в информационной системе поставщика',
    shorthelp_articles_purchaseprices_purchasePrice: 'Закупочная цена',
    shorthelp_articles_account_id: 'Счет продаж',
    shorthelp_articles_purchase_account_id: 'Счет покупок',
    shorthelp_articles_vat_id: 'Ставка НДС для продаж',
    shorthelp_articles_purchase_vat_id: 'Ставка НДС для покупок', shorthelp_units_code: 'Ühiku kood, unikaalne, kasutatakse valikutes',
    shorthelp_units_name: 'Наименование единицы измерения',
    shorthelp_units_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_currencies_code: 'Код валюты, уникальный, используется в выборах',
    shorthelp_currencies_name: 'Наименование валюты',
    shorthelp_currencies_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_currencies_rate: 'Курс валюты{br}Если базовая валюта - EUR, то он всегда равен 1',
    shorthelp_currencies_drate: 'Курс валюты{br}Курс валюты, установленный относительно базовой валюты',
    shorthelp_countries_code: 'Код страны, уникальный, используется в выборах',
    shorthelp_countries_name: 'Наименование страны',
    shorthelp_countries_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_countries_eu_code: 'Стандартный двухбуквенный код страны{br}См. ISO 3166-1 Alpha-2 code:{br}https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes',
    shorthelp_accounts_code: 'Код счета, уникальный, используется в выборах',
    shorthelp_accounts_name: 'Наименование счета',
    shorthelp_accounts_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_accounts_accountGroupId: 'Группа счетов{br}Активы, пассивы, доходы или расходы',
    shorthelp_accounts_type_id: 'Тип счета{br}Записи можно делать только по детальным счетам.{br}Общие счета суммируют подсчеты с тем же началом.',
    swift: 'Swift',
    shorthelp_wareshorthelp_banks_code: 'Код банка, уникальный, используется в выборах',
    shorthelp_banks_name: 'Наименование банка',
    shorthelp_banks_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_banks_swift: 'SWIFT-код банка',
    shorthelp_banks_eInvoiceChannel: 'Канал электронного счета банка',
    shorthelp_banks_address: 'Адрес банка',
    shorthelp_salesinvoices_currency_id: 'Выбор валюты счета на продажу',
    shorthelp_salesinvoices_drate: 'Курс валюты счета на продажу',
    eInvoiceChannel: 'Канал электронного счета',
    address: 'Адрес',

    shorthelp_banks_code: 'Panga kood, unikaalne, kasutatakse valikutes',
    shorthelp_warehouses_code: 'Код склада, уникальный, используется в выборах',
    shorthelp_warehouses_name: 'Наименование склада',
    shorthelp_warehouses_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_objects_code: 'Код объекта, уникальный, используется в выборах',
    shorthelp_objects_name: 'Наименование объекта',
    shorthelp_objects_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_vats_code: 'Код ставки НДС, уникальный, используется в выборах',
    shorthelp_vats_name: 'Наименование ставки НДС',
    shorthelp_vats_memo: 'Дополнительная информация для внутреннего использования',

    shorthelp_articlegroups_code: 'Код группы товаров, уникальный, используется в выборах',
    shorthelp_articlegroups_name: 'Наименование группы товаров',
    shorthelp_articlegroups_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_articlegroups_parent_id: 'Родительская группа группы товаров',
    shorthelp_articlegroups_account_id: 'Счет продаж{br}Используется при добавлении нового товара',
    shorthelp_articlegroups_vat_id: 'Ставка НДС для продаж{br}Используется при добавлении нового товара',
    shorthelp_articlegroups_purchase_account_id: 'Счет покупок{br}Используется при добавлении нового товара',
    shorthelp_articlegroups_purchase_vat_id: 'Ставка НДС для покупок{br}Используется при добавлении нового товара',
    shorthelp_pricelists_code: 'Код прайс-листа, уникальный, используется в выборах',
    shorthelp_pricelists_name: 'Наименование прайс-листа',
    shorthelp_pricelists_memo: 'Дополнительная информация для внутреннего использования',
    shorthelp_companies_pricelist_id: 'Прайс-лист по умолчанию{br}Используется при составлении документов на продажу', houses_memo: 'Lisainfo sisemiseks kasutamiseks',

    tooltip_show_sidebar: 'Показать боковую панель',

    vat_report: 'Отчет о НДС',
    shorthelp_vat_report_period_start: 'Дата начала периода{br}Обязательно',
    shorthelp_vat_report_period_end: 'Дата окончания периода{br}Обязательно',
    vat_name: 'Ставка НДС',
    vat_percent: 'НДС %',
    msg_drop_files_here: 'Перетащите файл сюда, чтобы добавить вложение',
    msg_save_document_to_attach: 'Сохраните данные документа, чтобы добавить вложения',

    unit_code: 'Единица',
    group_code: 'Группа',
    price_with_vat: 'Цена с НДС',
    sales_account: 'Счет продаж',
    purchases_account: 'Счет покупок',
    wh_qty: 'Остаток на складе',
    wh_total: 'Складской остаток €',
    reserved_purchase: 'Зарезервировано для покупки',
    reserved_sales: 'Зарезервировано для продажи',
    available: 'Доступный остаток',
    wh_min_qty: 'Мин. остаток на складе',
    purchase_need: 'Необходимость в покупке',
    purchase_price: 'Закупочная цена',
    purchase_currency_code: 'Валюта',
    supplier_code: 'Код поставщика',
    color: 'Цвет',
    group_id: 'Группа',
    supplier_id: 'Поставщик',
    shorthelp_articles_filter_group_id: 'Отображаются товары, принадлежащие выбранной группе товаров',
    shorthelp_articles_filter_supplier_id: 'Отображаются товары, поставщиком которых является выбранный',
    shorthelp_articles_filter_active: 'Фильтр работает только при включенном этом флажке',

    article_type_ext_0: 'Складской товар',
    article_type_ext_10: 'Продукция',
    article_type_ext_1000: 'Расходный материал',
    article_type_ext_2000: 'Показатель КУ',
    article_type_ext_3000: 'Постоянный платеж КУ',
    company_type: 'Тип',
    legal_address: 'Юридический адрес',
    postal_address: 'Почтовый адрес',
    due_days: 'Срок платежа',
    due_date_grid: 'Срок платежа',
    contract: 'Договор',
    pricelist_name: 'Прайс-лист',

    shorthelp_articles_filter_article_type_ext_0: 'Отображаются товары со склада',
    shorthelp_articles_filter_article_type_ext_10: 'Отображаются товары производства',
    shorthelp_articles_filter_article_type_ext_1000: 'Отображаются товары расходов',
    shorthelp_articles_filter_article_type_ext_2000: 'Отображаются примеры КУ',
    shorthelp_articles_filter_article_type_ext_3000: 'Отображаются постоянные платежи КУ',

    companygroups: 'Группы компаний',
    shorthelp_companies_filter_group_id: 'Отображаются компании, принадлежащие выбранной группе',
    shorthelp_companies_filter_customers: 'Отображаются клиенты',
    customers: 'Клиенты',
    shorthelp_companies_filter_suppliers: 'Отображаются поставщики',
    suppliers: 'Поставщики',
    shorthelp_companies_filter_active_companies: 'Отображаются активные компании',
    active_companies: 'Активные компании',
    shorthelp_companies_filter_closed_companies: 'Отображаются закрытые компании',
    closed_companies: 'Закрытые компании',
    shorthelp_companies_filter_active: 'Фильтр работает только при включенном этом флажке',

    show_ui_shortHelp: 'Показывать короткие подсказки, когда курсор мыши находится над элементом',
    'shorthelp_localSetup_ui.showHints': 'Показывать короткие подсказки, когда курсор мыши находится над элементом',
    setup_user_title: 'Настройки пользователя',
    total_without_vat: 'Сумма без НДС',
    vat_return_percent: 'Процент возврата',
    grid_salesinvoicerows: 'Товарные строки',
    purchase_payment_title: 'Информация об оплате',
    paid_in_cash: 'Оплачено наличными',
    payment_account_id: 'Оплата с учета',

    'due_period must be greater than or equal to 0': 'Срок платежа не может быть меньше нуля',
    expense_date: 'Дата возникновения расходов',
    title_registries: 'Реестры и отчеты',
    title_favourites: 'Избранное',
    title_notes: 'Заметки',
    shortcut_quick_add: 'Быстрое добавление',

    edit_companygroups_title: 'Изменение группы компаний {title}',
    new_companygroups_title: 'Новая группа компаний {title}',
    view_companygroups_title: 'Группа компаний {title}',

    view_general_ledger_title: 'Запись {title}',

    edit_companies_title: 'Изменение компании "{title}"',
    new_companies_title: "Новая компания {title}",
    view_companies_title: "Компания {title}",

    edit_articles_title: 'Изменить товар {title}',
    new_articles_title: 'Новый товар {title}',
    view_articles_title: 'Товар {title}',

    edit_salesinvoices_title: 'Изменить счет на продажу {title}',
    new_salesinvoices_title: 'Новый счет на продажу {title}',
    view_salesinvoices_title: 'Просмотреть счет на продажу {title}',
    new_salesinvoices_menu: 'Добавить счет на продажу',

    edit_purchaseinvoices_title: 'Изменить счет на покупку {title}',
    new_purchaseinvoices_title: 'Новый счет на покупку {title}',
    view_purchaseinvoices_title: 'Просмотреть счет на покупку {title}',
    new_purchaseinvoices_menu: 'Добавить счет на покупку',

    edit_units_title: 'Изменение единицы измерения {title}',
    new_units_title: 'Новая единица измерения {title}',

    new_currencies_title: 'Новая валюта {title}',
    edit_currencies_title: 'Изменить валюту {title}',

    edit_countries_title: 'Изменить страну {title}',
    new_countries_title: 'Новая страна {title}',
    new_companies_menu: 'Добавить новую компанию',
    new_articles_menu: 'Добавить новый товар',

    edit_accounts_title: 'Изменить счет {title}',
    new_accounts_title: 'Новый счет {title}',

    new_banks_title: 'Новый банк {title}',
    edit_banks_title: 'Изменить банк {title}',

    edit_warehouses_title: 'Изменить склад {title}',
    new_warehouses_title: 'Новый склад {title}',

    edit_objects_title: 'Изменить объект {title}',
    new_objects_title: 'Новый объект {title}',

    edit_vats_title: 'Изменить ставку НДС {title}',
    new_vats_title: 'Новая ставка НДС {title}',

    new_articlegroups_title: 'Новая группа товаров {title}',
    edit_articlegroups_title: 'Изменить группу товаров {title}',

    edit_pricelists_title: 'Изменить прайс-лист {title}',
    new_pricelists_title: 'Новый прайс-лист {title}',

    new_ledger_types_title: 'Новый тип записи {title}',
    edit_ledger_types_title: 'Изменить тип записи {title}',

    new_general_ledger_title: 'Новая запись {title}',
    edit_general_ledger_title: 'Изменить запись {title}',

    ledger_type_id: 'Тип записи',
    with_currency: 'Запись в валюте',
    document: 'Документ',
    debit: 'Дебет',
    credit: 'Кредит',
    total_d: 'Всего дебет',
    total_c: 'Всего кредит',
    grid_entries: 'Записи',

    not_implemeted_msg: 'Функционал еще не готов, используйте старый интерфейс',

    module_sales: 'Продажи',
    module_purchases: 'Покупки',
    module_common: 'Общее',
    module_accounting: 'Финансы',

    general_ledger: 'Главная книга',
    ledger_types: 'Типы записей',

    attachments: 'Вложения',
    drop_attachments: 'Новое вложение',

    vat_short: 'НДС',
    objs: 'Объекты',
    obj_on_rows: 'Разные объекты в строках',
    about: 'О программе',
    help: 'Помощь',
    // msg_archive_db: 'Kas oled kindel, et soovid andmebaasi kustutada?',

    gl_date: 'Дата проводки',
    supplier_code_company: 'Код поставщика',
    supplier_name: 'Имя поставщика',
    summaryobj: 'Объекты',
    document_modified: 'Документ изменен',

    logo_alignment: 'Расположение логотипа',
    logo_alignment_left: 'Слева',
    logo_alignment_center: 'По центру',
    logo_alignment_right: 'Справа',
    logo_width: 'Ширина логотипа (%)',
    number_in_words: 'Сумма прописью',
    ledger_type_code: 'КТ',
    ledger_type_name: 'Тип проводки',
    fiscal_period_code: 'Финансовый год',
    doc: 'Документ',
    unbound: 'Несвязанный',
    status_id: 'Статус',
    debit_base: 'Дебет база',
    credit_base: 'Кредит база',
    total_unbound: 'Всего несвязанных',

    module_capital_assets: 'Основные средства',
    ca_groups: 'Группы основных средств',
    edit_ca_groups_title: 'Изменить группу основных средств {title}',
    new_ca_groups_title: 'Новая группа основных средств {title}',
    edit_capital_assets_title: 'Изменить основное средство {title}',
    new_capital_assets_title: 'Новое основное средство {title}',
    capital_assets: 'Основные средства',
    purchase_date: 'Дата покупки',
    amort_start_date: 'Дата начала амортизации',
    purchase_value: 'Стоимость приобретения',
    residue_value: 'Остаточная стоимость',
    calculation_method: 'Метод расчета',
    ca_method_linear: 'Линейный',
    ca_method_withoud_amortisation: 'Без амортизации',
    amort_percent: 'Амортизация %',
    asset_account_id: 'Счет основных средств',
    amort_account_id: 'Счет амортизации',
    wear_account_id: 'Счет износа',
    grid_ca_modifications: 'Изменения',
    amort_date: 'Дата амортизации',
    modification: 'Изменение',
    percent: 'Процент',
    writeoff_block_title: 'Списание',
    writeoff_date: 'Дата списания',
    writeoff_amort_date: 'Дата амортизации списания',
    writeoff_memo: 'Дополнительная информация о списании',
    ca_amortisation_report: 'Отчет об амортизации',
    initial_value: 'Начальная стоимость',
    value_modification: 'Переоценка',
    amortisation: 'Амортизация',
    capital_asset_id: 'Основное средство',
    group_name: 'Группа',
    purchase_date_from: 'Дата покупки с',
    purchase_date_until: 'Дата покупки по',
    writeoff_date_from: 'Дата списания с',
    writeoff_date_until: 'Дата списания по',
    e_invoice_setup_title: 'Настройка электронных счетов',
    e_invoice_operator: 'Оператор',
    e_invoice_auth_key: 'Ключ аутентификации',
    e_invoice_environment: 'Среда',
    e_invoice_attach_pdf: 'Прикрепить свой PDF к счету',

    e_invoice_operator_finbite: 'Finbite',
    e_invoice_operator_rik: 'E-arveldaja / RIK',
    not_used: 'Не используется',
    e_invoice_environment_test: 'Тестовая среда',
    e_invoice_environment_live: 'Рабочая среда',
    e_invoice_setup_sales: 'Счета на продажу',
    e_invoice_setup_purchases: 'Счета на покупку',
    e_invoice_setup_rik: 'E-arveldaja / RIK',
    e_invoice_setup_finbite: 'Finbite',
    e_invoice_setup_sales_parties: 'Компании на электронном счете',
    e_invoice_buyer_party: 'Покупатель',
    e_invoice_payer_party: 'Плательщик',
    e_invoice_recipient_party: 'Получатель',
    not_exported: '--Не экспортируется--',
    e_invoice_party_buyer: 'Покупатель',
    e_invoice_party_payer: 'Плательщик',
    purchases_report: 'Отчет о покупках',

    msg_error_processing_link: 'Ошибка обработки ссылки',
    error: 'Ошибка',

    e_invoice_setup_general: 'Общее',

    percent_symbol: '%',
    ca_amortisation_report_title: 'Отчет об амортизации {period_start} - {period_end}',
    purchases_report_title: 'Отчет о покупках {period_start} - {period_end}',

    module_warehouse: 'Склад',
    wh_inits: 'Ввод начальных остатков',
    wh_code: 'Склад',
    wh_name: 'Название склада',
    dst_wh_id: 'Целевой склад',
    total_cost: 'Общая стоимость',

    new_wh_inits_title: 'Новый начальный остаток {title}',
    view_wh_inits_title: 'Просмотр начального остатка {title}',
    edit_wh_inits_title: 'Изменить начальный остаток {title}',
    grid_waybillrows: 'Товарные накладные',
    cost: 'Стоимость',
    wh_incomes: 'Поступления на склад',
    edit_wh_incomes_title: 'Поступление на склад {title}',
    new_wh_incomes_title: 'Новое поступление на склад {title}',
    view_wh_incomes_title: 'Просмотр поступления на склад {title}',

    wh_outcomes: 'Списание со склада',
    customer_name: 'Клиент',
    total_price: 'Общая цена продажи',
    edit_wh_outcomes_title: 'Списание со склада {title}',
    new_wh_outcomes_title: 'Новое списание со склада {title}',
    view_wh_outcomes_title: 'Просмотр списания со склада {title}',
    src_wh_id: 'Исходный склад',
    customer_id: 'Клиент',
    recipient_id: 'Получатель',

    total_cost_from: 'Общая стоимость от',
    total_cost_until: 'Общая стоимость до',
    total_price_from: 'Общая цена продажи от',
    total_price_until: 'Общая цена продажи до',

    wh_intra: 'Перемещения между складами',

    wh_src_name: 'Исходный склад',
    wh_dst_name: 'Целевой склад',
    wh_src_id: 'Исходный склад',
    wh_dst_id: 'Целевой склад',

    "Can't lock this type of document": 'Невозможно заблокировать этот тип документа',
    edit_wh_intra_title: 'Перемещение между складами {title}',
    new_wh_intra_title: 'Новое перемещение между складами {title}',
    view_wh_intra_title: 'Перемещение между складами {title}',
    wh_writeoffs: 'Списания',

    edit_wh_writeoffs_title: 'Списание со склада {title}',
    new_wh_writeoffs_title: 'Новое списание со склада {title}',
    view_wh_writeoffs_title: 'Списание со склада {title}',

    default_print_form: 'Форма печати по умолчанию',
    report_form: 'Форма отчета',

    invoicing_title: 'Выставление счетов',

    unit_integers_singular: 'Единица (единственное число)',
    unit_decimals_singular: 'Единица десятичной дроби (единственное число)',
    unit_integers_plural: 'Единица (множественное число)',
    unit_decimals_plural: 'Единица десятичной дроби (множественное число)',
    logo: 'Логотип',
    and: 'и',

    emails: 'Электронные письма',
    addr_to: 'Получатель',
    addr_cc: 'Копия (CC)',
    addr_bcc: 'Скрытая копия (BCC)',
    subject: 'Тема',
    body: 'Содержание',
    sent_date: 'Дата отправки',
    new_emails_title: 'Новое электронное письмо {title}',
    edit_emails_title: 'Электронное письмо {title}',
    view_emails_title: 'Электронное письмо {title}',

    setup_user_ui_title: 'Интерфейс пользователя',
    setup_email_title: 'Электронная почта',

    no_tls: 'TLS отсутствует',
    implicit_tls: 'Автоматический TLS (implicit)',
    explicit_tls: 'Ручной TLS (explicit)',
    require_tls: 'Требуется TLS (require)',
    change_password: 'Пароль',
    change_password_tooltip: 'Изменить пароль',
    new_password: 'Новый пароль',
    change_password_msg: 'Введите новый пароль и нажмите OK',
    email_not_sent: 'Электронное письмо не отправлено',
    email_sent: 'Электронное письмо отправлено',

    msg_loading_privileges: 'Загрузка привилегий',
    msg_no_read_privilege: 'Нет прав доступа (чтение)',
    language: 'Язык',
    lang_et: 'Eesti keeles',
    lang_en: 'In English',

    invoice_recipient: 'Получатель счета',
    invoice_reg_code: 'Регистрационный код',
    invoice_vat_code: 'НДС код',
    invoice_total: 'Сумма счета',
    invoice_condition: 'Условие',
    invoice_payment_conditions_long: 'Счет должен быть оплачен в течение {due_period} дней после выставления счета.',
    invoice_payment_interest_long: 'В случае задержки платежа будет начислен штраф {interest} % в день.',
    invoice_issuer_signature: 'Счет выставлен',
    invoice_recipient_signature: "Счет получен",

    signature: 'Подпись',
    article_or_service_name: 'Наименование товара/услуги',
    invoice_payment_conditions_no_vat: 'НДС не применяется.',
    error_attachment_unique_violation: 'Вложение с именем {name} уже добавлено',
    title_error: 'Ошибка',
    'Field nr should be set when adding new entry': 'При добавлении новой записи должно быть установлено поле номера',
    'Date is required': 'Дата обязательна',
    title_additional_info: 'Дополнительная информация',
    cn_code: 'Код ТН ВЭД',
    name2: 'Наименование на другом языке',
    grid_unit_conversions: 'Правила конвертации',
    converted_unit_id: 'Конвертированная единица',
    coef: 'Коэффициент',
    title_no_read_privilege: 'Нет прав на чтение',
    eefr_main_account: 'Экономическое содержание',
    eefr_data_presentment: 'Способ представления данных',
    eefr_card_title: 'Данные годового отчета',
    grid_eefr_company_related_parties: 'Связанные стороны',
    eefr_related_party: 'Связанная сторона',
    eefr_asset_group: 'Группа активов',
    eefr_change_type: 'Тип изменения',
    from: 'С',
    until: 'По',
    'User not found': 'Пользователь не найден',
    eefr_report: 'Данные годового отчета (RIK)',
    account_main_id: 'MS',
    account_main_desc: 'Экон. содержание',
    dp_id: 'AEV',
    ag_id: 'VG',
    ct_id: 'ML',
    rp_id: 'SOP',

    subaccount_aev_desc: 'описание aev',
    account_code: 'Код счета',
    d_base: 'Дебет',
    c_base: 'Кредит',
    eefr_report_title: 'Данные годового отчета {period_start} - {period_end}',
    has_eefr: 'Есть данные годового отчета',
    title_salesinvoices_due: 'Непогашенные счета на продажу',
    more: 'Еще',
    'error loading setup': 'Ошибка загрузки настроек',
    create_company_database_title: 'Создать базу данных компании',
    create_company_database_msg: 'Введите название и тип базы данных компании и нажмите OK',
    tip_enter_company_name: 'Введите название компании (минимальная длина 3 символа)',
    tip_company_name_too_short: 'Название компании слишком короткое',
    to_frontpage: 'На главную',
    msg_user_name_not_unique: 'Такое имя пользователя уже занято',
    msg_user_name_invalid: 'Имя пользователя может содержать только буквы, цифры, точки и подчеркивания',
    billing_report: 'Отчет о расчетах',
    billing_dt: 'Дата',
    amount: 'Сумма',
    company: 'Компания',
    doc_type: 'Тип документа',
    doc_nr: 'Номер документа',

    'database created': 'База данных создана',
    'sales': 'Продажи',

    'auth/invalid-email': 'Неверный адрес электронной почты',
    'auth/missing-password': 'Пароль обязателен',
    'auth/user-not-found': 'Пользователь не найден',
    'auth/wrong-password': 'Неверный пароль',
    page_not_found: 'Не найдено',
    page_not_found_message: 'Искомая страница {path} не найдена',
    doc_privacy_title: 'Политика конфиденциальности',
    doc_terms_of_service_title: 'Условия использования',

    // 2024-04-26
    unknown_page: 'Неизвестная страница',
    msg_unknown_page: 'Страница не найдена: {regName}',
    doc_load_error: 'Ошибка загрузки документа',
    doc_load_error_message: 'Ошибка загрузки документа',
    doc_index_title: 'Дружественное бизнес-программное обеспечение',
    hint_language: 'Выбор языка',
    lang_ru: 'По-русски',
    prices: 'Цены',
    package: 'Пакет',
    billing_package_sales: 'Продажи',
    billing_package_sales_description: 'Счета на продажу, управление клиентами, прайс-листы, связь с финансовым модулем',
    doc_prices_title: 'Цены',
    eur: 'EUR',
    eur_symbol: '€',
    'auth/weak-password': 'Пароль слишком слабый, он должен состоять как минимум из 6 символов',
    'auth/email-already-in-use': 'Адрес электронной почты уже используется',
    'auth/invalid-login-credentials': 'Неверное имя пользователя или пароль',
    msg_already_have_account: 'Уже есть аккаунт?',
    // msg_register_info: 'Нажимая "Создать аккаунт", вы соглашаетесь с нашими ',
    agree_to_terms: 'Условиями использования',
    agree_to_privacy: 'Политикой конфиденциальности',
    login_header: 'Войти',
    reset_password_link: 'Забыли пароль?',
    reset_password_email_sent: 'Мы отправили письмо с инструкциями по восстановлению пароля',
    msg_no_account: 'Еще не пользователь Profit?',
    billing_package_purchases: 'Покупки',
    billing_package_purchases_description: 'Счета на покупку, управление поставщиками, связь с финансовым модулем',
    'No bank account found for sender': 'Банковский счет отправителя не найден',



    setup_bank_accounts_title: 'Банковские счета',
    setup_update_db_title: 'Обновление базы данных',
    own_bank_accounts: 'Банковские счета',
    paymenttypes: 'Способы оплаты',
    edit_paymenttypes_title: 'Изменить способ оплаты {title}',
    new_paymenttypes_title: 'Новый способ оплаты {title}',
    prefer_for_bank_outcome: 'Предпочтительно для платежей',
    print_on_bank_requisits: 'Печать на банковских реквизитах',
    cashorder: 'Кассовый ордер',
    bank_account: 'Номер банковского счета',
    iban: 'IBAN',
    title_report_loading_error: 'Ошибка загрузки отчета',
    msg_report_loading_error_long: 'Не удалось загрузить отчет. {error}',
    log_entry: 'Запись в журнале',
    btn_copy_to_clipboard: 'Скопировать в буфер обмена',
    btn_copied_to_clipboard: 'Скопировано в буфер обмена',
    priority: 'Приоритет',
    setup_warehouse_title: 'Склад',
    
    'LADU.AUTOSTL.MYYK': 'Автоматические накладные с продажных счетов',
    'LADU.AUTOSTL.OST': 'Автоматические накладные с закупочных счетов',
    
    report_run_table: 'Таблица',
    report_run_table_tooltip: 'Предварительный просмотр отчета в формате таблицы',
    report_run_json: 'JSON',
    report_run_json_tooltip: 'Выходной отчет в формате JSON',
    
    eefr_data: 'Данные MAA (RIK)',
    eefr_section_EE0301010: 'Секция данных микропредприятий Балансы EE0301010',
    eefr_section_EE0301020: 'Стандартная секция данных Балансы EE0301020',
    eefr_section_EE0302010: 'Доходы за период EE0302010',
    eefr_data_sections: 'Секции данных',
    
    close_preview: 'Закрыть предварительный просмотр',
    attachments_inline_preview_setup: 'Настройка предварительного просмотра вложений',
    ui_att_inline: 'Включить предварительный просмотр вложений на карточке документа',
    ui_hints_show: 'Показывать короткие подсказки при наведении мыши на элемент',
    ui_att_inline_right: 'Предварительный просмотр вложений справа, если возможно',
    ui_att_inline_in_doc: 'Предварительный просмотр вложений на карточке документа',
    msg_no_delete_privilege: 'Нет прав на удаление',
    msg_no_create_privilege: 'Нет прав на создание',
    msg_no_save_privilege: 'Нет прав на сохранение',
    balance_report: 'Баланс',
    date1: 'Дата 1',
    date2: 'Дата 2',
    date3: 'Дата 3',
    date4: 'Дата 4',
    report: 'Отчет',   


    // 2024-09-06
    profit_report: 'Отчёт о прибыли',
    period1_start: 'Начало периода 1',
    period1_end: 'Конец периода 1',
    period2_start: 'Начало периода 2',
    period2_end: 'Конец периода 2',
    period3_start: 'Начало периода 3',
    period3_end: 'Конец периода 3',
    period4_start: 'Начало периода 4',
    period4_end: 'Конец периода 4',
    
    'report_id is required': 'Выбор отчёта обязателен',
    accounts_report: 'Отчёт по счёту',
    with_correspondence: 'С корреспонденцией',
    corr_account_id: 'Корреспондирующий счёт',
    entry_order: 'Порядок ввода',
    order_by_date: 'По дате',
    type: 'Тип',
    ledger_nr: 'Номер проводки',
    corr_account: 'Корр. счёт',
    saldo: 'Сальдо',
    start_saldo: 'Начальное сальдо',
    end_saldo: 'Конечное сальдо',
    accounts_report_title: 'Отчёт по счёту {period_start} - {period_end}',
    wh_state_report: 'Складской остаток',
    name_code: 'Наименование, Код',
    group_code_article_code: 'Группа, Код',
    group_name_code: 'Группа, Наименование, Код',
    wh_location_code: 'Местоположение склада, Код',
    wh_location_name_code: 'Местоположение склада, Наименование, Код',
    wh_state_report_title: 'Складской остаток {dt}',
    dt: 'Дата',
    group: 'Группа',
    no_null: 'Не показывать строки с нулями',
    no_closed: 'Не показывать закрытые строки с нулевым остатком',
    reserved: 'Бронирования',

    wh_article_ledger: 'Отчёт об обороте товара',
    wh_article_ledger_title: 'Отчёт об обороте товара {period_start} - {period_end}',
    wh_article_ledger_section_initial: 'Начальный остаток',
    wh_article_ledger_section_in: 'Поступления',
    wh_article_ledger_section_out: 'Выбытия',
    wh_article_ledger_section_final: 'Конечный остаток',
    qty: 'Количество',
    source: 'Источник',
    destination: 'Назначение',
    sales_price: 'Цена продажи',
    section: 'Секция',
    other_side: 'Противоположная сторона',
    wh_income_report_det: 'Отчёт о поступлениях на склад',
    doc_date_row_nr: 'Дата',
    company_name_doc_nr_row_nr: 'Компания, Номер документа',
    wh_income_report_det_title: 'Отчёт о поступлениях на склад {period_start} - {period_end}',
    wh_outcome_report_det: 'Отчёт о выбытиях со склада',
    wh_outcome_report_det_title: 'Отчёт о выбытиях со склада {period_start} - {period_end}',
    income_expense_report: 'Отчёт о доходах и расходах',
    income_expense_report_title: 'Отчёт о доходах и расходах',
    section_title_incomes: 'Доходы',
    section_title_expenses: 'Расходы',
    section_title_profit_loss: 'Прибыль (-Убыток)',
    expense: 'Расход',
    income: 'Доход',
    unpaid_bills_report: 'Отчёт о неоплаченных счетах',
    report_type: 'Тип отчёта',
    filter_overdue: 'Только просроченные счета',
    overdue_days: 'Количество просроченных дней',
    company_group_id: 'Группа компаний',
    unpaid_bills_report_title: 'Отчёт о неоплаченных счетах {report_date}',
    remainder: 'Остаток',
    menu: 'Меню',
    open_article_card: 'Открыть карточку товара',
    tooltip_add_row: 'Добавить строку',
    wh_init_warehouse_must_be_set: 'Целевой склад должен быть установлен',
    open_account_card: 'Открыть карточку счёта',
    waybill_no: 'Номер накладной',
    recipient: 'Получатель',
    warehouse: 'Склад',
    consignor: 'Отправитель товара',
    issuer_signature: 'Выдал',
    recipient_signature: 'Получил',
    msg_register_info: 'Создавая аккаунт, вы соглашаетесь с нашими ',
    msg_google_register_info: 'Вы также можете зарегистрироваться, используя Google аккаунт',
    msg_google_login_info: 'Вы также можете войти, используя Google аккаунт',
    select_objects: 'Выбор объекта',
    show_eefr_panel: 'Показать панель MAA',
    show_article_name_column: 'Отображать название товара',
    show_currency_selector: 'Отображать выбор валюты',
    show_object_selector: 'Отображать выбор объекта',
    alert_document_saved_grn_to_purchase: 'Счёт на покупку создан',
    alert_document_saving_grn_to_purchase: 'Создание счёта на покупку',
    alert_document_failed_grn_to_purchase: 'Не удалось создать счёт на покупку',
    
    alert_document_saving_purchase_to_gin: 'Создание накладной на выбытие со склада',
    alert_document_saved_purchase_to_gin: 'Накладная на выбытие со склада создана',
    alert_document_saving_purchase_to_grn: 'Создание накладной на поступление на склад',
    alert_document_saved_purchase_to_grn: 'Накладная на поступление на склад создана',
    alert_document_failed_purchase_to_grn: 'Не удалось создать накладную на поступление на склад',
    alert_document_failed_purchase_to_gin: 'Не удалось создать накладную на выбытие со склада',
    
    alert_document_failed_gin_to_salesinvoice: 'Не удалось создать счёт-фактуру',
    alert_document_saving_gin_to_salesinvoice: 'Создание счёта-фактуры',
    alert_document_saved_gin_to_salesinvoice: 'Счёт-фактура создан',
    
    alert_document_failed_lock: 'Не удалось зафиксировать документ',
    alert_document_saved_lock: 'Документ зафиксирован',
    alert_document_saving_lock: 'Фиксация документа',
    
    alert_document_failed_unlock: 'Не удалось снять фиксацию',
    alert_document_saved_unlock: 'Фиксация снята',
    alert_document_saving_unlock: 'Снятие фиксации',
    
    alert_document_saved_salesinvoice_to_grn: 'Накладная на поступление на склад создана',
    alert_document_saving_salesinvoice_to_grn: 'Создание накладной на поступление на склад',
    alert_document_failed_salesinvoice_to_grn: 'Не удалось создать накладную на поступление на склад',
    
    alert_document_saved_salesinvoice_to_gin: 'Накладная на выбытие со склада создана',
    alert_document_saving_salesinvoice_to_gin: 'Создание накладной на выбытие со склада',
    alert_document_failed_salesinvoice_to_gin: 'Не удалось создать накладную на выбытие со склада',
    invoice_due_date: 'Срок оплаты',
    invoice_interest: 'Пеня',
    percent_per_dat: '% в день',
    salesinvoice: 'Счёт-фактура',
    print_code_column: 'Столбец с кодом',
    print_row_memo: 'Печать примечаний к строке',
    'Database with this name already exists': 'База данных с таким именем уже существует',
    correspondent_account: 'Корр. счёт',
    article_groups: 'Группы товаров',
    wh_location: 'Местоположение склада',
    subaccounts: 'Субсчета',
    profit_report_title: 'Отчёт о прибыли',
    
    'No value for parameter \'period_start\' provided': 'Обязательный параметр "Начало периода" не указан',
    'No value for parameter \'period_end\' provided': 'Обязательный параметр "Конец периода" не указан',
    'No value for parameter \'dt\' provided': 'Обязательный параметр "Дата" не указан',
    
    period_1: 'Период 1',
    period_2: 'Период 2',
    period_3: 'Период 3',
    period_4: 'Период 4',
}

export default messages