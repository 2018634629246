import { ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

export const wh_income_report_sort_orders = [
    {value: 'doc_date,doc_nr,row_nr', label: 'date'},
    {value: 'dst,doc_date,doc_nr,row_nr', label: 'destination'},
];

export const paramTypes: ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'},
    'f_article_id': {type: 'select', selectorRd: 'articles', title: 'article_id'},
    'f_wh_id': {type: 'select', selectorRd: 'warehouses', title: 'wh_id'},
    'f_company_id': {type: 'select', selectorRd: 'companies', title: 'company_id'},
};
