import React from 'react'
import { RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from '../../../regs';
import * as Yup from 'yup';

const CountryDetailForm = React.lazy(() => import('./CountryDetailForm'))

class CountriesRD extends RegistryDescriptor {
    gridEndpoint = 'countries'
    docEndpoint = 'countries'
    selector = {
        endpoint: 'countries',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
    }
    name: ApplicationUnit = 'countries'
    columns =  [
        {name: 'code'},
        {name: 'name'},
    ]
    defaultDocument = {status: 0}
    getDetailForm = (docPath: string) => <CountryDetailForm docPath={docPath} />
    getTitle = (doc: any): string  => {
        if(!!doc && doc.name)
            return doc.name
        else
            return ''
    }

    public getValidationSchema(): Yup.ObjectSchema<any> {
        return Yup.object().shape({
            code: Yup.string().required('Required').max(20).uppercase(),
            name: Yup.string().required('Required').max(100),
            eu_code: Yup.string().max(2).uppercase(),
        });
    }

}

export const countries = new CountriesRD()
