import { RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from "../../../regs";

class EReportSelector extends RegistryDescriptor {
    constructor(name: ApplicationUnit) {
        super();
        this.name = name;
        this.selector = {
            endpoint: name as string,
            idCol: 'id',
            captionCol: 'code',
            textCol: 'name',
            queryParams: { _orderBy: 'code' }
        };
    }
}

export const ereports = new EReportSelector('ereports');
