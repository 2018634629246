import React from "react";
import { DocGridColumn, RegistryDescriptor } from "../../../../app/types";
import { getCompanyData } from "../../../businessreg";
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import { RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";
import { RegistryFilterOperator } from "../../../../app/types/types";
import { ApplicationUnit } from "../../../regs";

const CompanyDetailPage = React.lazy(() => import('./CompanyDetailPage'));
const CompanyExtrasPage = React.lazy(() => import('./CompanyExtrasPage'));
const FilterForm = React.lazy(() => import('./CompaniesFilterForm'));

class CompaniesRD extends RegistryDescriptor {
    gridEndpoint = 'companies_grid'
    docEndpoint = 'companies'
    selector = {
        endpoint: 'companies_selector',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
        queryParams: {
            // TODO: specific RegistryDescriptor for customers
            // _filter: JSON.stringify([{field: 'customer', operator: '=', value: 1}]),
            _orderBy: 'code'
        },
        // memoCol: ''
    }
    name: ApplicationUnit = 'companies'

    columns: DocGridColumn[] = [
        // {name: 'id'},
        {name: 'code'},
        {name: 'name'},
        {name: 'reg_code'},
        {name: 'vat_code'},
        {name: 'company_type'},
        {name: 'phone'},
        {name: 'email'},
        {name: 'contact'},
        {name: 'legal_address'},
        {name: 'postal_address'},
        {name: 'due_days', type: 'int', align: 'right', width: 50},
        {name: 'contract'},
        {name: 'pricelist_name'},
        {name: 'discount', type: 'int', align: 'right', width: 30},

        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]

    defaultDocument = {
        customer: true,
        supplier: true,
        collections: {
            companycontacts: [],
            bank_accounts: [],
            // measurers: [{nr: 1}],
            // companyenumerations: [{nr: 1}]
        }
    }

    childrenDescriptors = {
        'collections/companycontacts': {
            enumeratedField: 'nr',
            initialNrValue: 1,
            defaultValue: {},
        },
        'collections/bank_accounts': {
            enumeratedField: 'nr',
            initialNrValue: 1,
            defaultValue: {},
        },
        'collections/eefr_company_related_parties': {
            enumeratedField: 'nr',
            initialNrValue: 1,
            defaultValue: {},
        }
    }

    public isFilterable = () => true
    public getFilterForm = (docPath: string) => <FilterForm docPath={docPath} />

    regFilterTranslations: RegFilterTranslations = {
        group_id: { field: 'group_id', operator: '=' },
    }

    public translateFilter(filter: any) : RegistryFilterOperator[] {
        const ret : RegistryFilterOperator[] = []
        if(filter.active) {
            console.log('active filter', filter)
            if(filter.group_id)
                ret.push({field: 'group_id',operator: '=', value: filter.group_id})

            if(!!filter.customers !== !!filter.suppliers) {
                console.log('customers/suppliers filter', filter.customers, filter.suppliers)
                if(filter.customers)
                    ret.push({field: 'customer', operator: '=', value: '1'})
                if(filter.suppliers)
                    ret.push({field: 'supplier', operator: '=', value: '1'})
            }

            if(!!filter.active_companies !== !!filter.closed_companies) {
                if(filter.active_companies)
                    ret.push({field: 'closed', operator: '=', value: '0'})
                if(filter.closed_companies)
                    ret.push({field: 'closed', operator: '=', value: '1'})
            }
        }
        console.log('translated filter', ret)
        return ret
    }    
    public afterLoad(doc: any) { 
        doc = {...this.defaultDocument, ...doc}
        if(doc.__setup) {
            if (doc.__setup.name)
                doc.name = doc.__setup.name
            if (doc.__setup.code)
                doc.code = doc.__setup.code
        }
        return doc
    }

    public async beforeSave(doc: any) : Promise<any> {
        const r1 = this.cleanupChildren(doc)
        const ret = await this.validate(r1)
        return r1
    }


    public getTitle(doc: any): string {
        if(!!doc && doc.name)
            return doc.name
        else
            return ''
    }

    getDetailForm = (docPath: string) => <CompanyDetailPage docPath={docPath}/>

    onUpdate = async (doc: any, field: string, val: any) => {
        console.log('onUpdate: ' + field + ' = ' + val)
        if(field === 'legal_address_street' && !doc.postal_address_street)
            doc.postal_address_street = val
        if(field === 'legal_address_postal_code' && !doc.postal_address_postal_code)
            doc.postal_address_postal_code = val
        if(field === 'legal_address_city' && !doc.postal_address_city)
            doc.postal_address_city = val
        if(field === 'legal_address_country_id' && !doc.postal_address_country_id)
            doc.postal_address_country_id = val

        if(field === 'name') {
            // fetch data from äriregister and fill the fields
            const data = await getCompanyData(val)
            if(data.status === 'ok') {
                doc.reg_code = data.regCode
                doc.vat_code = data.vatCode
                doc.legal_address_street = data.addrStreet1
                doc.legal_address_postal_code = data.addrIndex
                doc.legal_address_city = data.addrCity
                doc.postal_address_street = data.addrStreet1
                doc.postal_address_postal_code = data.addrIndex
                doc.postal_address_city = data.addrCity
                // TODO country from data.addrCountry
                if(!doc.code)
                    doc.code = data.regCode
                doc.phone = data.phone
                doc.email = data.email
                doc.contact_person = data.contactPerson
            }
        }
        return doc
    }

}

class CompanyExtrasRD extends CompaniesRD {
    getDetailForm = (docPath: string) => <CompanyExtrasPage docPath={docPath} />
}

export const companies = new CompaniesRD()
export const companyExtras = new CompanyExtrasRD()