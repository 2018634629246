import { ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

export const accounts_report_sort_orders = [
    {value: 'article_code', label: 'code'},
    {value: 'article_name,article_code', label: 'name_code'},
    {value: 'group_name,article_code', label: 'group_code_article_code'},
    {value: 'group_name,article_name,article_code', label: 'group_name_code'},
    {value: 'wh_location,article_code', label: 'wh_location_code'},
    {value: 'wh_location,article_name,article_code', label: 'wh_location_name_code'},
];

export const paramTypes: ParamTypesDescriptors = {
    'dt': {type: 'date'},
    'wh_id': {type: 'select', selectorRd: 'warehouses', title: 'wh_id'},
    'groups': {type: 'select', selectorRd: 'articlegroups', title: 'article_groups'},
    'objs': {type: 'select', selectorRd: 'objects', title: 'objects'},
};
