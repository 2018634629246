import { FormatDateOptions } from "react-intl"

export const SETUP_PREFIX_GRID = 'grid'
export const SETUP_SUFFIX_SORTED = 'sorted'
export const SETUP_SUFFIX_SORTDIR = 'sortDir'
export const ASC = 'asc'
export const DESC = 'desc'
export const SETUP_LOCALE = 'locale'

export const INTL_DATEFORMAT: FormatDateOptions = {day: '2-digit', month: '2-digit', year: 'numeric'}
export const INTL_TIMEFORMAT: FormatDateOptions = {hour: '2-digit', minute: '2-digit'}
export const INTL_DATETIMEFORMAT: FormatDateOptions = {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}

export const SUM_FORMAT = {
    maximumFractionDigits: 2, 
    minimumFractionDigits: 2,
    useGrouping: true
}

export const HELP_URL = 'https://help.intellisoft.ee'

export const SHARED_SETUP_KEYS = Object.freeze({
    COMPANY_NAME: 'FIRMA.NAME',
    COMPANY_REG_CODE: 'FIRMA.KOOD',
    COMPANY_VAT_CODE: 'FIRMA.KKNR',
    COMPANY_ADDRESS: 'FIRMA.AADRESS',
    COMPANY_PHONE: 'FIRMA.TELEFON',
    COMPANY_EMAIL: 'FIRMA.EMAIL',
    COMPANY_CURRENCY: 'FIRMA.VALUUTA',
    COMPANY_CURRENCY2: 'FIRMA.VALUUTA2',
    COMPANY_PRICELIST: 'DEF.FIRMAD.HINNAKIRIID',
    COMPANY_COUNTRY: 'FIRMA.RIIK',

    PRINTFORM: 'SALES.PRINTFORM',

    LOGO_ALIGNMENT: 'LOGO.ALIGNMENT',
    LOGO_WIDTH: 'LOGO.WIDTH',

    DEF_COMPANIES_PRICELISTID: 'DEF.FIRMAD.HINNAKIRIID',
    DEF_COMPANIES_DUEDAYS: 'DEF.FIRMAD.MAKSETAHTAEG',
    DEF_COMPANIES_SUPPLIER_ACCOUNT: 'DEF.FIRMAD.HANKIJAKONTOID',
    DEF_COMPANIES_CUSTOMER_ACCOUNT: 'DEF.FIRMAD.KLIENDIKONTOID',
    DEF_COMPANIES_VAT_TYPE: 'DEF.FIRMAD.KMLIIKID',
    DEF_COMPANIES_KMDINF: 'DEF.FIRMAD.KMDINF',

    E_INVOICE_OPERATOR: 'EARVE.OPERATOR',
    E_INVOICE_AUTH_KEY: 'OMNIVA.AUTHKEY',
    E_INVOICE_ENV: 'OMNIVA.ENV',
    E_INVOICE_ATTACH_PDF: 'OMNIVA.PDF',
    E_INVOICE_BUYER_PARTY: 'EARVE.BUYERPARTY',
    E_INVOICE_PAYER_PARTY: 'EARVE.PAYERPARTY',
    E_INVOICE_RECIPIENT_PARTY: 'EARVE.RECIPIENTPARTY',

    WH_OUTCOME_AUTOMATIC: 'LADU.AUTOSTL.MYYK',
    WH_INCOME_AUTOMATIC: 'LADU.AUTOSTL.OST',

    SALESINVOICE_PRINT_CODE_COLUMN: 'SALESINVOICE.PRINT.CODE',
    SALESINVOICE_PRINT_ROW_MEMO: 'SALESINVOICE.PRINT.ROW.MEMO',

    EEFR_ENV: 'SETUP.AA.ENV',
    EEFR_ENV_DEV: 'SETUP.AA.DEV',
    EEFR_USER: 'SETUP.AA.USER',
    EEFR_SECRET: 'SETUP.AA.SECRET',
    EEFR_NACE: 'SETUP.AA.DEF_EMTAK',
    EEFR_REPORT_TYPE: 'SETUP.AA.MAA_ID',
    EEFR_MAIN_ACCOUNT: 'SETUP.AA.CC_MAJSISU',
    EEFR_RELATED_PARTY: 'SETUP.AA.CC_SEOTUDOSAPOOL',
});

export const USER_SETUP_KEYS = Object.freeze({
    EMAIL_SMTP_SERVER: 'EMAIL.SMTP.SERVER',
    EMAIL_SMTP_PORT: 'EMAIL.SMTP.PORT',
    EMAIL_SMTP_USER: 'EMAIL.SMTP.UID',
    EMAIL_SMTP_PASSWORD: 'SEC.EMAIL.SMTP.PWD',
    EMAIL_SMTP_BCC: 'EMAIL.SMTP.BCC',
    EMAIL_SMTP_TLS: 'EMAIL.SMTP.TLS',
    EMAIL_USESMTP: 'EMAIL.USESMTP',
    EMAIL_SMTP_SIGNATURE: 'EMAIL.SMTP.SIGNATURE',
    EMAIL_SUBJECT_DEFAULT: 'EMAIL.SUBJECT.DEFAULT',
});

export const LOCAL_SETUP_KEYS = Object.freeze({
    THEME_COLORSCHEME_NAME: 'colorSchemeName',
    THEME_DENSITY_NAME: 'densityName',

    DATABASESLIST_ARCHIVING_VISIBLE: 'databaseslist.archiving.visible',
    DATABASESLIST_ARCHIVED_VISIBLE: 'databaseslist.archived.visible',


    SALESINVOICES_CURRENCY_VISIBLE: 'salesinvoice.currency.visible',
    SALESINVOICES_PAYER_VISIBLE: 'salesinvoice.payer.visible',
    SALESINVOICES_INTEREST_VISIBLE: 'salesinvoice.interest.visible',
    SALESINVOICES_NAME_COLUMN_VISIBLE: 'salesinvoice.namecolumn.visible',
    SALESINVOICES_UNIT_COLUMN_VISIBLE: 'salesinvoice.unitcolumn.visible',
    SALESINVOICES_UNIT_COLUMN_EDITABLE: 'salesinvoice.unitcolumn.editable',
    SALESINVOICES_VAT_COLUMN_VISIBLE: 'salesinvoice.vatcolumn.visible',
    SALESINVOICES_VAT_COLUMN_EDITABLE: 'salesinvoice.vatcolumn.editable',
    SALESINVOICES_DISCOUNT_COLUMN_VISIBLE: 'salesinvoice.discountcolumn.visible',
    SALESINVOICES_TOTAL_WO_VAT_COLUMN_VISIBLE: 'salesinvoice.totalwovatcolumn.visible',
    SALESINVOICES_TOTAL_VAT_COLUMN_VISIBLE: 'salesinvoice.totalvatcolumn.visible',
    SALESINVOICES_TOTAL_WITH_VAT_COLUMN_VISIBLE: 'salesinvoice.totalwithvatcolumn.visible',
    SALESINCOICES_TOTALS_REVENUES_VISIBLE: 'salesinvoice.totals.revenues.visible',
    SALESINVOICES_EEFR_VISIBLE: 'salesinvoice.eefr.visible',

    PURCHASES_CURRENCY_VISIBLE: 'purchase.currency.visible',
    PURCHASES_OBJ_VISIBLE: 'purchase.obj.visible',
    PURCHASES_EEFR_VISIBLE: 'purchase.eefr.visible',

    
    WH_DOC_OBJECTS_VISIBLE: 'wh.doc.objects.visible',

    GL_OBJ_VISIBLE: 'gl.obj.visible',
    GL_INVOICE_NR_VISIBLE: 'gl.invoice_nr.visible',
    GL_DUE_DATE_VISIBLE: 'gl.due_date.visible',
    GL_INTEREST_VISIBLE: 'gl.interest.visible',

    UI_HINTS_SHOW: 'ui_hints_show',
    UI_ATT_INLINE: 'ui_att_inline',
    UI_ATT_INLINE_RIGHT: 'ui_att_inline_right',
    UI_ATT_INLINE_WIDTH: 'ui_att_inline_width',
    UI_ATT_INLINE_IN_DOC: 'ui_att_inline_in_doc',
});

// Temporary solution for link translations
// TODO - remove when proper solution is implemented
export const linkRefTranslations = [
    { from: '#card:kanded_',                to: '/general_ledger/' },
    { from: '#card:kaubaliikumised_',       to: '/salesinvoices/' },
    { from: '#card:emails_',                to: '/emails/' },
    { from: '#kaart:47020_',                to: '/wh_outcomes/' }, 
    { from: '#kaart:47010_',                to: '/wh_incomes/' }, 
    { from: '#card:ostuarved_',             to: '/purchaseinvoices/' },
];

export const WAYBILL_TYPE = Object.freeze({
    INIT: 0,
    INCOME: 1,
    OUTCOME: 2,
    INTRA: 3,
    PRODUCTION: 4,
    WRITEOFF: 5,
    INVENTORY: 6,
    REVALUATION: 7,    // Ümberhindlus
})