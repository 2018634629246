import React from "react";
import { ReportDescriptor, ReportOutputKind } from "../../../../app/types/ReportDescriptor";
import { paramTypes } from "./const";
import { ApplicationUnit } from "../../../regs";
import { ReportDataDescriptor, ReportParams, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";
import { DocGridColumn } from "../../../../app/types";

const Form = React.lazy(() => import('./UnpaidBillsReportForm'));

class UnpaidBillsReportRD extends ReportDescriptor {

    reportEndpoint: string = 'unpaid_bills_report';
    name: ApplicationUnit = 'unpaid_bills_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public getOutputKinds(): ReportOutputKind[] {
        return ['pdf', 'json', 'table'];
    }

    public getTabularReportColumns(params: ReportParams): DocGridColumn[] {
        return [
            { name: 'company_code', width: 80, label: 'company' },
            { name: 'company_name', width: 200, label: 'name' },
            { name: 'invoice_nr', width: 100, label: 'invoice_nr' },
            { name: 'ledger_date', width: 100, label: 'doc_date', type: 'date' },
            { name: 'due_date', width: 100, label: 'due_date', type: 'date' },
            { name: 'interest', type: 'decimal', width: 100, label: 'interest' },
            { name: 'invoice_total', type: 'decimal', width: 100, label: 'total' },
            { name: 'balance', type: 'decimal', width: 100, label: 'remainder' },
        ];
    }

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params)};   //TODO: order selector?
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./UnpaidBillsReportPrintForm');

}

export const unpaid_bills_report = new UnpaidBillsReportRD();