export const isDevMode = process.env.NODE_ENV === 'development';

export const APPCENTRAL_URL = isDevMode ? 'http://localhost:8085/pwc' : 'https://services.intellisoft.ee/pwc'; 

export const APP_URLS = {
    FRONTPAGE: '/hello/',
    BILLING: '/billing/',
    LOGIN: '/login',
    TERMS: '/doc/terms_of_service',
    PRIVACY: '/doc/privacy',
    REGISTER: '/register',
}

// 'https://profit.intellisoft.ee/api';
