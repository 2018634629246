import { ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

export const wh_income_report_sort_orders = [
    {value: 'doc_date,nr_prefix,nr,nr_suffix,row_nr', label: 'doc_date_row_nr'},
    {value: 'company_name,nr_prefix,nr,nr_suffix,row_nr,row_nr', label: 'company_name_doc_nr_row_nr'},
];

export const paramTypes: ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'},
    'f_article_id': {type: 'select', selectorRd: 'articles', title: 'article_id'},
    'f_wh_id': {type: 'select', selectorRd: 'warehouses', title: 'wh_id'},
    'f_company_id': {type: 'select', selectorRd: 'companies', title: 'company_id'},
    'grp_ids': {type: 'select', selectorRd: 'articlegroups', title: 'article_groups'},
    'obj_ids': {type: 'select', selectorRd: 'objects', title: 'objects'},
    '_orderBy': {type: 'options', items: wh_income_report_sort_orders, title: 'sort_order'},
};
