import React from "react";
import { Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { SUM_FORMAT } from "../../../app/const";

export default function WhAggCostPanel({ agg }: { agg: any }) {
    const intl = useIntl();

    return <Box sx={{ p: 1, display: 'flex', ml: 8 }}>
        <Box sx={{ mr: 4 }}>
            <FormattedMessage id='total' />
        </Box>
        <Box sx={{ mr: 8 }}>
            {intl.formatNumber(agg?.total_cost || 0, SUM_FORMAT)}
        </Box>
    </Box>
}