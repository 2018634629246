import React from "react";
import { DocPathParams } from "../../../../app/types";
import ComboEdit from "../../../../ui/components/edit/ComboEdit";
import DateEdit from "../../../../ui/components/edit/DateEdit";

export default function CapitalAssetsFilterForm(params: DocPathParams) {
    return <>
        <DateEdit {...params} field='purchase_date_from' />
        <DateEdit {...params} field='purchase_date_until' />
        <DateEdit {...params} field='writeoff_date_from' />
        <DateEdit {...params} field='writeoff_date_until' />

        <ComboEdit {...params} field='obj_id' reg='objects' />
        <ComboEdit {...params} field='group_id' reg='ca_groups' />
        <ComboEdit {...params} field='asset_account_id' reg='accounts' />
    </>
}

export const ca_filterTranslations = {
    purchase_date_from: { field: 'purchase_date', operator: '>=' },
    purchase_date_until: { field: 'purchase_date', operator: '<=' },
    writeoff_date_from: { field: 'writeoff_date', operator: '>=' },
    writeoff_date_until: { field: 'writeoff_date', operator: '<=' },
    obj_id: { field: 'obj_id', operator: '=' },
    group_id: { field: 'group_id', operator: '=' },
    asset_account_id: { field: 'asset_account_id', operator: '=' },
}
