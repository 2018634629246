import { ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

export const accounts_report_sort_orders = [
    {value: 'entry_order', label: 'entry_order'},
    {value: 'order_by_date', label: 'order_by_date'},
];

export const paramTypes: ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'},
    'account_id': {type: 'select', selectorRd: 'accounts', title: 'account_id'},
    'object_id': {type: 'select', selectorRd: 'objects', title: 'object_id'},
    'currency_id': {type: 'select', selectorRd: 'currencies', title: 'currency_id'},
    'sort_order': {type: 'options', items: accounts_report_sort_orders, title: 'sort_order'},
    'corr_account_id': {type: 'select', selectorRd: 'accounts', title: 'correspondent_account'},
    // TODO korrespondeeruvusega
};
