import {useState, useEffect, useLayoutEffect} from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../../redux/store'
import { selectLocalSetupValue } from "../../redux/localSetup/localSetupSlice";
import { LOCAL_SETUP_KEYS } from "../const";
import { isDevMode } from "../../configs/appConfig";
import { Theme } from '@mui/system';
import { useMediaQuery } from "@mui/material";


type ScreenType = 'mobile' | 'tablet' | 'desktop' | 'large';

export const useScreenType = () : ScreenType => {

    /**
    *    // extra-small
    *    xs: 0,
    *    // small
    *    sm: 600,
    *    // medium
    *    md: 900,
    *    // large
    *    lg: 1200,
    *    // extra-large
    *    xl: 1536,
    */

    const matchesMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const matchesTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
    const matchesHD = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'xl'));
    const matches4k = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

    if(matchesMobile) return 'mobile';
    if(matchesTablet) return 'tablet';
    if(matchesHD) return 'desktop';
    if(matches4k) return 'large';

    return 'tablet';
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

//export const useSelectLanguage = () => useAppSelector(selectLanguage)


export type ScreenSize = "small" | "large" | undefined;

export function useScreenSize() {
    const [screenSize, setScreenSize] = useState<ScreenSize>(undefined);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const newSize = (screenWidth > 1000) && (screenHeight >= 600) ? "large" : "small";
            setScreenSize(newSize);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return screenSize;
}

export type ColorScheme = 'light' | 'dark' | 'system';

export function useProfitColorScheme() {
    const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
    const colorSchemeName = selectLocalSetupValue(LOCAL_SETUP_KEYS.THEME_COLORSCHEME_NAME);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setColorScheme(mediaQuery.matches ? 'dark' : 'light');
        const listener = (event: MediaQueryListEvent) => {
            setColorScheme(event.matches ? 'dark' : 'light');
        };
        mediaQuery.addEventListener('change', listener);
        return () => {
            mediaQuery.removeEventListener('change', listener);
        };
    }, []);

    if (!isDevMode)
        return 'light';

    const usedColorSchemeName = !colorSchemeName || colorSchemeName === 'system' ? colorScheme : colorSchemeName;
    return usedColorSchemeName;
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const useWindowHeight = () => {
    const [height, setHeight] = useState(window.innerHeight);
    useLayoutEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return height;
}

export const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
    useLayoutEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return width;
}