import React from 'react'
import { SelectorListItem } from "../../../../redux/selectors/selectorsSlice";
import { SimpleCodedNamedWithMemoRD } from '../../../../app/types/SimpleCodedNamedWithMemoRD';

const ArticleGroupEdit = React.lazy(() => import('./ArticleGroupEdit'))

class ArticleGroupsRD extends SimpleCodedNamedWithMemoRD {

    defaultDocument = {status: 0}
    
    getDetailForm = (docPath: string) =>  <ArticleGroupEdit docPath={docPath} />

    constructor() {
        super('articlegroups')
        this.selector.getOptionLabel = (option: SelectorListItem) => option.caption
    }
}

export const articlegroups = new ArticleGroupsRD()