import React from "react";
import { ReportDescriptor } from "../../../../app/types/ReportDescriptor";
import { ApplicationUnit } from "../../../regs";
import { ParamTypesDescriptors, ReportDataDescriptor, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";

const Form = React.lazy(() => import('./BalanceForm'));

const paramTypes : ParamTypesDescriptors = {
    'date1': {type: 'date'},
    'date2': {type: 'date'},
    'date3': {type: 'date'},
    'date4': {type: 'date'},
    'currency_id': {type: 'select', selectorRd: 'currencies', title: 'currency'},
    'object_id': {type: 'select', selectorRd: 'objects', title: 'object'},
};

class BalanceRD extends ReportDescriptor {

    reportEndpoint: string = 'balance_report';
    name: ApplicationUnit = 'balance_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        return await this.prepareReportData(this.getReportParams(params), paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./BalancePrintForm');
}

export const balance_report = new BalanceRD();