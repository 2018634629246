import { APPCENTRAL_URL, isDevMode } from "../configs/appConfig";
import { getAuthToken } from "../redux/auth/authSlice"


/**
 * Fetch data from ProfitCentral API, check status and return data.
 * If data.status is not ok, then throw an error with data.message
 * @param url full url to fetch from
 * @returns retrieved data as JS object
 */
export const getFromProfitCentral = async (url: string) => {
    let token;
    try {
        token = await getAuthToken();
    } catch (e) {
        console.log('No token, will request anonymously');
        token = undefined;
    }
    const fullUrl = APPCENTRAL_URL + url;
    if(isDevMode) console.log('GET ', fullUrl);

    const response = await fetch(
        fullUrl,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
    const data = await response.json();
    if(data.status !== 'ok') {
        console.warn('Error from ProfitCentral API', data);
        throw new Error(data.message);
    }
    return data;
}

/**
 * @brief Get company names for given partial from Estonian Business Register via Intellisoft's API
 * @param partial partial company name
 * @returns array of existing company names including the partial name
 */
export const getProfitUsersSelector = async(partial: string) : Promise<any> =>
    (await getFromProfitCentral('/?e=user&m=userslist&q=' + partial)).list.map((item: any) => item.name);

export const getMyProfile = async () : Promise<any> => 
    await getFromProfitCentral('/?e=user&m=profile');

export const saveMyName = async (name: string) : Promise<any> =>
    await getFromProfitCentral('/?e=user&m=save_name&n=' + name);

export const archiveDB = async (db: string) : Promise<any> =>
    await getFromProfitCentral('/?e=user&m=archive&id=' + db);

export const saveEnterpriseName = async (db: string, name: string) : Promise<any> =>
    await getFromProfitCentral('/?e=user&m=save_enterprise_name&n=' + name + '&db=' + db);