import React from "react";
import { ReportDescriptor } from "../../../../app/types/ReportDescriptor";
import { ApplicationUnit } from "../../../regs";
import { TabularReportDataDescriptor, ReportDataDescriptor, ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

const Form = React.lazy(() => import('./AmortisationReportForm'));

const paramTypes : ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'},
    'capital_asset_id': {type: 'select', selectorRd: 'capital_assets', title: 'capital_asset_id'},
    'group_id': {type: 'select', selectorRd: 'ca_groups', title: 'group_id'},
    'obj_id': {type: 'select', selectorRd: 'objects', title: 'obj_id'},
};

class CaAmortReportRD extends ReportDescriptor {

    reportEndpoint: string = 'ca_amortisation_report';
    name: ApplicationUnit = 'ca_amortisation_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = {...this.getReportParams(params), _orderBy: 'code'};
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData : ReportDataDescriptor) => 
        await import('./AmortisationReportPrint');
}

export const ca_amortisation_report = new CaAmortReportRD();