import { get } from "../../profit/api";
import { getRegDescriptor } from "../../profit";
import { store } from "../../redux/store";
import { SelectEditItem } from "../../ui/components/edit/SelectEdit";
import { ApplicationUnitDescriptor, ApplicationUnitType, ParamTypesDescriptors, TabularReportDataDescriptor, ReportParams } from "./ApplicationUnitDescriptor";
import { isArray } from "lodash";

export type ReportOutputKind = 'pdf' | 'table' | 'json'

export class ReportDescriptor extends ApplicationUnitDescriptor {
    type: ApplicationUnitType = 'report'
    reportEndpoint: string = ''

    public getReportForm(docPath: string): JSX.Element | null {
        return null
    }

    /**
     * @returns list of available output kinds, by default only pdf
     * @see ReportOutputKind
     */
    public getOutputKinds(): ReportOutputKind[] {
        return ['pdf'];
    }

    public getReportTitle(): string | undefined {
        return this.name + '_title';
    }

    public getReportParams(params: any): ReportParams {
        const reportParams: ReportParams = {}
        try {
            Object.entries(params).forEach(([key, value]) => {
                if (key.startsWith(this.name + '.')) {
                    const parName = key.substring(this.name!.length + 1);
                    reportParams[parName] = value;
                }
            })
        } catch (e) { 
            console.warn(e);
        }
        return reportParams;
    }

    public async getTranslatedReportParams(reportParams: ReportParams, paramTypes: ParamTypesDescriptors) {

        // filter used params
        const ppp = Object.keys(paramTypes)
            .filter(key => !!reportParams[key] && (!isArray(reportParams[key]) || reportParams[key].length > 0))
            .map(key => {
                const ptype = paramTypes[key as keyof typeof paramTypes];
                return { key: key, value: reportParams[key], ...ptype }
            })

        // resolve params
        const rp = await Promise.all(ppp.map(async (pt: any) => {

            if (pt) {
                if ((pt.type === 'select' || pt.type === 'multiselect') && pt.selectorRd) {
                    const sel = getRegDescriptor(pt.selectorRd).selector;
                    const state = store.getState();
                    const url = state.databases.currentDatabase!.uri + '/' + sel.endpoint;
                    const multisel = isArray(pt.value)

                    const filter = {
                        field: sel.idCol,
                        operator: multisel ? 'in' : '=',
                        value: multisel ? pt.value.join(',') : pt.value,
                    }

                    // if (pt.type === 'select') {
                    const resp = await get(
                        state,
                        url,
                        { _filter: JSON.stringify([filter])},
                    );

                    return {
                        ...pt,
                        title: pt.title || pt.key,
                        value: multisel ? resp.data.map((i: any) => i[sel.textCol]).join('; ') : resp.data[0][sel.textCol]
                    };
                }

                if (pt.type === 'options' && pt.items && pt.items.length > 0) {
                    const item = pt.items.find((it: SelectEditItem) => it.value === pt.value);
                    return { ...pt, title: pt.title || pt.key, value: item?.label || pt.value };
                }
            }

            return { ...pt, title: pt?.title || pt.key };
        }))

        return rp;
    }

    public async prepareReportData(reportParams: ReportParams, paramTypes: ParamTypesDescriptors): Promise<TabularReportDataDescriptor> {
        const state = store.getState()
        const db = state.databases.currentDatabase!.uri
        const response = await get(state, db + '/' + this.reportEndpoint, reportParams)

        return {
            title: this.getReportTitle(),
            input: await this.getTranslatedReportParams(reportParams, paramTypes),
            inputRaw: reportParams,
            output: response.data,
        }
    }
}
