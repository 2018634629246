import React from "react";
import { DocGridColumn, KeyValueList, RegistryDescriptor } from "../../../../app/types";
import { updateDocFieldValue } from "../../../../redux/docs/utils";
import { useIntl } from "react-intl";
import { getFromCurrentDB } from "../../../api";
import { store } from "../../../../redux/store";
import { INTL_DATEFORMAT } from "../../../../app/const";
import * as Yup from 'yup';
import { round2 } from "../../../../app/utils";
import * as validators from "../../../../app/validationUtils";
import { DocLink } from "../../../../redux/docLinks/docLinksSlice";
import GeneralLedgerAggPanel from "./GeneralLedgerAggPanel";

import { ApplicationUnit } from "../../../regs";

const GeneralLedgerEdit = React.lazy(() => import('./GeneralLedgerEdit'));
const FilterForm = React.lazy(() => import("./GeneralLedgerFilterForm"));

class GeneralLedgerRD extends RegistryDescriptor {

    // properties specific to this registry
    name: ApplicationUnit = 'general_ledger'

    defaultDocument = {
        status: 1,
        total_d: 0,
        total_c: 0,
        has_settlements: false,
        deprecated_unbound: 0,
        linked_document_id: 0,
        deprecated_invoice: 0,
        order_nr: 0,
        deprecated_locked: 0,
        with_currency: 0,
        collections: {
            entries: [],
            ledger_links: [],
        }
    }

    default_row = {
        date_paid: '3000-01-01',
        obj_id: 0,
        // quantity: 1,
        // name: '',
        // vat_return_percent: 0,
        // vat_coef: 0,
        // total_vat: 0
    }

    gridEndpoint = 'general_ledger_grid'
    docEndpoint = 'general_ledger'
    selector = {
        endpoint: 'general_ledger',   //TODO special endpoint
        idCol: 'id',
        captionCol: 'nr',
        textCol: 'date',
        memoCol: 'document'
    }

    childrenDescriptors = {
        'collections/entries': {
            enumeratedField: 'nr',
            initialNrValue: 1,
            defaultValue: this.default_row,
        }
    }

    public isReadOnly(doc: any): boolean {
        return !this.isNew(doc) && (doc?.status !== 1);
    }


    getTitle = (doc: any) => {
        const intl = useIntl()
        return doc ? (!!doc.nr ? doc.nr : '') + ' : ' + intl.formatDate(doc.date, INTL_DATEFORMAT) : ''
    }

    getDetailForm = (docPath: string) => <GeneralLedgerEdit docPath={docPath} />

    public getGridFooterAggPanel(agg: any): JSX.Element | null {
        return <GeneralLedgerAggPanel agg={agg} />
    }

    regFilterTranslations = {
        doc_date_from: { field: 'gl_date', operator: '>=' },
        doc_date_until: { field: 'gl_date', operator: '<=' },
        ledger_type_id: { field: 'ledger_type_id', operator: '=' },
        total_from: { field: 'total', operator: '>=' },
        total_until: { field: 'total', operator: '<=' },
        account_id: { field: 'account_id', operator: '=' },
        obj_id: { field: 'obj_id', operator: '=' },
        // currency_id: { field: 'currency_id', operator: '=' },
    }

    public isFilterable(): boolean {
        return true
    }

    public getFilterForm(docPath: string): JSX.Element | null {
        return <FilterForm docPath={docPath} />
    }

    /**
     * @param setup General setup state object
     * @returns Filtered setup key-value pairs applicable to this type of document
     */
    public getSetup(setup: KeyValueList): KeyValueList {
        // Override this method to apply setup to the document
        // console.log('salesinvoices.getSetup', setup)
        return {
            def_currency_id: setup['DEF.VALUUTAID'] || '0',
        }
    }

    // public getSettingsSidebarItems() {
    //     return []
    // }

    // public async loadReportData(params: any): Promise<any> {
    //     return null
    // }

    columns: DocGridColumn[] = [
        // { name: 'locked', type: 'locked_icon', label: 'locked_icon', width: 30 },
        { name: 'ledger_type_code', type: 'string', width: 30 },
        { name: 'ledger_type_name', type: 'string', width: 120 },
        { name: 'fiscal_period_code', type: 'string', width: 60 },
        { name: 'nr', width: 60, align: 'right' },
        { name: 'gl_date', type: 'date', width: 100, label: 'date' },
        { name: 'doc', type: 'string' },
        { name: 'total', type: 'decimal', align: 'right' },
        { name: 'unbound', type: 'decimal', align: 'right' },
        { name: 'status_id' },

        // ...modificationColumns,
        // ...attachmentsCountColumns,
        // ...labelsColumns,
    ]

    public getValidationSchema(): Yup.ObjectSchema<any> | undefined {
        return Yup.object().shape({
            date: validators.date_required(),
            ledger_type_id: validators.integer_required_positive(),
            has_eefr: Yup.boolean(),
            collections: Yup.object().shape({
                entries: Yup.array().of(
                    Yup.object().shape({
                        account_id: validators.integer_required_positive(),
                        debit: validators.non_negative_decimal2(),
                        credit: validators.non_negative_decimal2(),
                        memo: Yup.string().max(500),
                    })
                )
            })
        });
    }

    // public isReadOnly(doc: any): boolean {
    //     return false; //doc && (doc.status !== 1);
    // }


    // public isLockable(): boolean {
    //     return true;
    // }

    // public isLocked(doc: any): boolean {
    //     return doc && !!doc.status;
    // }

    /** 
     * @brief update document_date when date is changed and document date is undefined
    */
    public on_update_date = async (doc: any, value: any) => {

        const ret = (await getFromCurrentDB(store.getState(), 'fetch_general_ledger_data', { gl_date: value })).data[0];
        console.log('ret', ret)
        if (ret.ret < 1)
            throw new Error(ret.state);
        return {
            ...doc,
            nr: ret.nr,
            fiscal_period_id: ret.period_id
        }
    }

    // /**
    //  * @param setup General setup state object
    //  * @returns Filtered setup key-value pairs applicable to this type of document
    //  */
    // public getSetup(setup: KeyValueList): KeyValueList {
    //     return {
    //         default_payment_account_id: setup['OSTUD.SR.KONTO'] || '',
    //     }
    // }

    protected on_update_entries_currency_id = async (doc: any, row: any, rowIndex: number, new_debit: number) => {
        return this.calculate_row_statics(doc, row)
    }

    /**
     * @brief when debit is changed calculate totals
     * @param doc 
     * @param row 
     * @param rowIndex 
     * @param new_debit 
     * @returns 
     */
    protected on_update_entries_debit = async (doc: any, row: any, rowIndex: number, new_debit: number) => {

        return this.calculate_row_statics(doc, {
            ...row,
            credit: new_debit ? 0 : row.credit
        })
    }

    /**
     * @brief when credit is changed calculate totals
     * @param doc 
     * @param row 
     * @param rowIndex 
     * @param new_credit
     * @returns 
     */
    protected on_update_entries_credit = async (doc: any, row: any, rowIndex: number, new_credit: number) => {

        return this.calculate_row_statics(doc, {
            ...row,
            debit: new_credit ? 0 : row.debit
        })
    }

    // TODO
    protected calc_doc_totals = (doc: any) => {
        let total_d: number = 0;
        let total_c: number = 0;
        doc.collections.entries.forEach((r: any) => {
            if (!isNaN(r.debit_base)) total_d += r.debit_base;
            if (!isNaN(r.credit_base)) total_c += r.credit_base;
        })
        return { ...doc, total_d: total_d, total_c: total_c };
    }

    // /**
    //  * @brief = (row.quantity * row.price * row.vat_percent / 100) rounded to 2 decimals
    //  * @param row invoice row
    //  * @returns vat total rounded to 2 decimals
    //  */
    // private calculate_total_vat = (row: any) => round2(row.quantity * row.price * row.vat_percent / 100)

    // TODO calc 
    protected calculate_row_statics = async (doc: any, row: any) => {

        // console.log('calculate_row_statics', row)
        console.log('setup', doc)

        const c: { rate: number, drate: number, id: number } = (doc.with_currency ?
            (await this.get_cached_currencies()).find((i: any) => i.id === row.currency_id)
            :
            undefined
        ) || {
            rate: 1,
            drate: 1,
            id: doc.__setup.def_currency_id
        };


        const ret = {
            ...row,
            debit: row.debit || 0,
            credit: row.credit || 0,
            currency_id: c.id,
            rate: c.rate || 1,
            drate: c.drate || 1,
            debit_base: this.calculate_base(row.debit, c.rate, c.drate), // round2(row.debit * c.rate / c.drate || 0),
            credit_base: this.calculate_base(row.credit, c.rate, c.drate) //round2(row.credit * c.rate / c.drate || 0),
        }

        console.log('ret:', ret);

        return ret;
    }

    protected calculate_base(amount: number, rate: number, drate: number): number {
        return round2((amount * (rate || 1) / (drate || 1)) || 0);
    }

    /**
     * calculate totals on every row update
     * @param doc 
     * @param field 
     * @param val 
     * @returns 
     */
    protected on_update_entries = async (doc: any, field: string, val: any): Promise<any> =>
        this.calc_doc_totals(doc)
    // TODO maybe dont need to recalculate every time?

    protected on_delete_entries = (doc: any, path: string): any =>
        this.calc_doc_totals(doc)


    private cached_currencies: any[] = []

    /**
     * 
     * @returns array of cached currencies
     */
    private async get_cached_currencies(): Promise<any[]> {
        if (!this.cached_currencies.length) {
            const cr = await getFromCurrentDB(store.getState(), 'currencies');
            this.cached_currencies = cr.data;
        }
        return this.cached_currencies;
    }

    private cached_settlement_accounts: any[] = []

    /**
     * 
     * @returns list of cached settlement accounts
     */
    private async get_cached_settlement_accounts(): Promise<any[]> {
        if (!this.cached_settlement_accounts.length) {
            const cr = await getFromCurrentDB(store.getState(), 'settlement_accounts');
            this.cached_settlement_accounts = cr.data;
        }
        return this.cached_settlement_accounts;
    }


    public async beforeSave(doc: any): Promise<any> {

        const r1 = this.cleanupChildren(doc)
        const r2 = await this.validate(r1)
        const ret = this.calc_doc_totals(r2)

        // const ro : any[] = []
        // ret.collections.entries.forEach((row: any) => {
        //     // console.log('objs for row', row.nr)
        //     // console.log('obj_on_rows', ret.obj_on_rows)
        //     if(ret.obj_on_rows && row.objs) {
        //         if(row.objs.length)
        //             row.objs.forEach((obj_id: number) => {
        //                 if(obj_id)
        //                     ro.push({
        //                         nr: row.nr,
        //                         obj_id: obj_id
        //                     })
        //         })
        //     } else
        //         if(ret.objs && ret.objs.length)
        //             ret.objs.forEach((obj_id: number) => {
        //                 if(obj_id)
        //                     ro.push({
        //                         nr: row.nr,
        //                         obj_id: obj_id
        //                     })
        //             })
        // })

        // ret.collections.purchaseinvoice_row_objects = ro

        // // console.log('resulting collection', ret.collections.purchaseinvoice_row_objects, ' >> ', ro)
        return ret
    }

    public async afterCopy(doc: any) {
        return {
            ...doc,
            id: undefined,
            // status: 0,
            // einvoice_transaction: '',
            attachments_count: 0,
            nr: ''
        }
    }

    /**
     * GL specific row update checks
     * Adds/updates memo on last newly appended row
     * Calculates debit or credit on last row to balance the document
     * @param doc document
     * @param field path updated
     * @param val new value
     * @returns row updated
     */
    public async check_row_updated(doc: any, field: string, val: any): Promise<any> {
        const ret = await super.check_row_updated(doc, field, val);
        if (field.startsWith('collections/entries/')) {
            if (field.endsWith('memo')) {
                ret.__last_memo = val;
                if (ret.collections.entries.length && ret.collections.entries[ret.collections.entries.length - 1].__appended)
                    ret.collections.entries[ret.collections.entries.length - 1] = {
                        ...ret.collections.entries[ret.collections.entries.length - 1],
                        memo: val
                    }
            }

            if (field.endsWith('/debit') || field.endsWith('/credit')) {
                console.log('checking D or C', field, val);
                let d = 0; let c = 0;
                if (ret.collections.entries.length) {
                    ret.collections.entries.forEach((r: any) => {
                        console.log('CHECKING row', r);
                        if (!(r.__appended && !r.__modified)) {
                            d += this.calculate_base(r.debit, r.rate, r.drate);
                            c += this.calculate_base(r.credit, r.rate, r.drate);
                            console.log(r, r.debit, r.credit, d, c);
                        }
                    });
                    console.log('calculated D and C', d, c);
                    if (d === c) {
                        d = 0;
                        c = 0;
                    } else if (d > c) {
                        d = d - c;
                        c = 0;
                    } else {
                        c = c - d;
                        d = 0;
                    }
                    ret.collections.entries[ret.collections.entries.length - 1] = await this.calculate_row_statics(doc, {
                        ...ret.collections.entries[ret.collections.entries.length - 1],
                        debit: c,
                        credit: d
                    });
                    console.log('debit or credit changed ', d, c);
                }
            }
        }
        return ret;
        // return this.calc_doc_totals(ret);
    }

    public create_new_collection_item(doc: any, path: string): any {
        // console.log('create_new_collection_item', path);
        const ret = super.create_new_collection_item(doc, path);
        if (path === 'collections/entries') {
            if (doc.__last_memo === undefined) {
                if (doc.collections.entries.length)
                    ret.memo = doc.collections.entries[doc.collections.entries.length - 1].memo || '';
            } else
                ret.memo = doc.__last_memo;
        }
        return ret;
    }

    /**
     * @brief set up flags and collections after document is loaded
     * @param doc document, purchaseinvoice
     * @returns modified document
     */
    public async afterLoad(doc: any) {

        // const ret = {...doc};
        let has_settlements = false;
        let has_eefr = false;

        const sa = await this.get_cached_settlement_accounts();
        if (doc && doc.collections && doc.collections.entries)
            doc.collections.entries.forEach((e: any) => {
                if (!has_settlements) {
                    const f = sa.find((i: any) => i.account_id === e.account_id);
                    if (f && f.account_id)
                        has_settlements = true;
                }

                if (!has_eefr) {
                    if (!!e.eefr_related_party || !!e.eefr_asset_group || !!e.eefr_change_type || !!e.eefr_data_presentment)
                        has_eefr = true;
                }
            });


        // console.log('afterLoad', doc)

        // const today = formatDateDB(new Date())

        // doc.obj_on_rows = false
        // let last_objs_s = ''

        // try {
        //     const vats = await this.get_cached_vats()

        //     if (doc?.collections?.purchaseinvoice_rows !== undefined && doc.collections.purchaseinvoice_rows.length !== undefined)
        //         doc.collections.purchaseinvoice_rows = doc.collections.purchaseinvoice_rows.map((row: any) => {
        //             const v = vats.find(v => v.id === row.vat_id)
        //             const objs = doc.collections.purchaseinvoice_row_objects
        //                 .filter((o: any) => o.nr === row.nr)
        //                 .map((o: any) => o.obj_id)
        //             return this.calculate_row_totals({
        //                 ...row,
        //                 vat_percent: v?.vat_percent,
        //                 total_vat_modified: !!row.vat_id && row.total_vat != this.calculate_total_vat({
        //                     quantity: row.quantity,
        //                     price: row.price,
        //                     vat_percent: v?.vat_percent
        //                 }),
        //                 // collect row objects
        //                 objs: objs,
        //                 _objs_s: objs.sort().join(';')
        //             })
        //         })

        //         doc.collections.purchaseinvoice_rows.forEach((item : any) => {
        //             if(!item.__appended) {
        //                 if(last_objs_s == '')
        //                     last_objs_s = item._objs_s
        //                 if(item._objs_s != last_objs_s)
        //                     doc.obj_on_rows = true
        //             }
        //         })

        // } catch (e) {
        //     console.warn('afterLoad', e)
        // }


        // doc.objs = doc.obj_on_rows ? [] : last_objs_s.split(';').map((s: string) => Number.parseInt(s))


        return this.calc_doc_totals({
            ...this.defaultDocument,
            ...doc,
            has_settlements,
            has_eefr
        })
    }

}

export const general_ledger = new GeneralLedgerRD()