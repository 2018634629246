import React, { Suspense } from "react";
import { DocGridColumn } from "../../../../app/types";
import { FormattedMessage, useIntl } from "react-intl";
import { INTL_DATEFORMAT, SUM_FORMAT, WAYBILL_TYPE } from "../../../../app/const";
import { ApplicationUnit } from "../../../regs";
import { WaybillsRD } from "../WaybillsRD";
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import { RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";

const EditForm = React.lazy(() => import('./WhWriteoffsEdit'));
const AggPanel = React.lazy(() => import('../WhAggInOutPanel'));
const FilterForm = React.lazy(() => import('./WhWriteoffsFilterForm'));

class WhWriteoffsRD extends WaybillsRD {

    // properties specific to this registry
    name: ApplicationUnit = 'wh_writeoffs'

    gridEndpoint = 'wh_writeoffs'
    selector = {
        endpoint: 'wh_writeoffs',   //TODO special endpoint
        idCol: 'id',
        captionCol: 'd_caption',
        textCol: 'date',
        memoCol: 'memo'
    }

    defaultDocument = {
        type_id: WAYBILL_TYPE.WRITEOFF,
        nrprefix: '',
        nrsuffix: '',
        src_wh_id: 1,
        dst_wh_id: 1,
        collections: {
            waybillrows: [],
            waybillrowobjects: [],
        }
    }

    // getTitle = (doc: any) => {
    //     const intl = useIntl()
    //     return doc ? (!!doc.nr ? doc.nr : '') + ' : ' + intl.formatDate(doc.date, INTL_DATEFORMAT) : ''
    // }

    public async afterLoad(doc: any) {

        const def_wh_id = this.get_def_wh_id(doc);

        const enm = !doc.id && !!def_wh_id && !!doc.date
            ? await this.fetch_doc_enumeration({
                date: doc.date,
                doc_type_id: WAYBILL_TYPE.WRITEOFF,
                src_wh_id: def_wh_id,
                dst_wh_id: 1,
            })
            : {};

        const ret = {
            ...this.defaultDocument,
            ...doc,
            ...enm,
            src_wh_id: def_wh_id,
        };

        return await super.afterLoad(ret); // this.calc_doc_totals(ret);
    }

    // Forms
    public getDetailForm = (docPath: string) => <EditForm docPath={docPath} />
    public getGridFooterAggPanel = (agg: any): JSX.Element | null => <AggPanel agg={agg} />    
    public getFilterForm = (docPath: string): JSX.Element | null => <FilterForm docPath={docPath} />

    protected get_type_id = () : number => WAYBILL_TYPE.WRITEOFF;

    regFilterTranslations : RegFilterTranslations = {
        doc_date_from: { field: 'doc_date', operator: '>=' },
        doc_date_until: { field: 'doc_date', operator: '<=' },
        total_cost_from: { field: 'total_cost', operator: '>=', type: 'decimal' },
        total_cost_until: { field: 'total_cost', operator: '<=', type: 'decimal' },
        src_wh_id: { field: 'wh_id', operator: '=', type: 'selected_id_int' },
        article_id: { field: 'article_id', operator: '=', type: 'selected_id_int' },
        obj_id: { field: 'obj_id', operator: '=', type: 'selected_id_int' },
    }

    columns: DocGridColumn[] = [
        { name: 'locked', type: 'locked_icon', label: 'locked_icon', width: 30 },
        { name: 'd_caption', type: 'string', width: 80, label: 'nr' },
        { name: 'doc_date', type: 'date', width: 100, label: 'date' },
        { name: 'wh_name', type: 'string', width: 150 },
        { name: 'total_cost', type: 'decimal', width: 100, align: 'right' },
        { name: 'summaryobj', type: 'string', width: 100 },
        { name: 'memo', type: 'string', width: 200 },
        { name: 'summaryrows', type: 'string', width: 200 },
        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]

    public isLockable = (): boolean => true;
}

export const wh_writeoffs = new WhWriteoffsRD()