import { GridLocaleText } from '@mui/x-data-grid'

const etGridLocale: GridLocaleText = {
    // Root
    noRowsLabel: 'Ridu ei ole',
    noResultsOverlayLabel: 'Midagi ei leitud.',

    // Density selector toolbar button text
    toolbarDensity: 'Tihedus',
    toolbarDensityLabel: 'Tihedus',
    toolbarDensityCompact: 'Kompaktne',
    toolbarDensityStandard: 'Standardne',
    toolbarDensityComfortable: 'Mugav',

    // Columns selector toolbar button text
    toolbarColumns: 'Veerud',
    toolbarColumnsLabel: 'Vali veerud',

    // Filters toolbar button text
    toolbarFilters: 'Filtrid',
    toolbarFiltersLabel: 'Näita filtrid',
    toolbarFiltersTooltipHide: 'Peida filtrid',
    toolbarFiltersTooltipShow: 'Näita filtrid',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} aktiivseet filtrit` : `${count} aktiivne filter`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Otsi…',
    toolbarQuickFilterLabel: 'Otsi',
    toolbarQuickFilterDeleteIconLabel: 'Puhasta',

    // Export selector toolbar button text
    toolbarExport: 'Eksport',
    toolbarExportLabel: 'Eksport',
    toolbarExportCSV: 'Lae alla CSV',
    toolbarExportPrint: 'Print',
    toolbarExportExcel: 'Lae alla Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Otsi veergu',
    columnsPanelTextFieldPlaceholder: 'Veeru pealkiri',
    columnsPanelDragIconLabel: 'Järjesta veerg',
    columnsPanelShowAllButton: 'Näita kõik',
    columnsPanelHideAllButton: 'Peida kõik',

    // Filter panel text
    filterPanelAddFilter: 'Lisa filter',
    filterPanelRemoveAll: 'Eemalda kõik',
    filterPanelDeleteIconLabel: 'Kustuta',
    filterPanelLogicOperator: 'Loogikaoperaator',
    filterPanelOperator: 'Operaator',
    filterPanelOperatorAnd: 'Ja',
    filterPanelOperatorOr: 'Või',
    filterPanelColumns: 'Veerud',
    filterPanelInputLabel: 'Väärtus',
    filterPanelInputPlaceholder: 'Filtri väärtus',

    // Filter operators text
    filterOperatorContains: 'sisaldab',
    filterOperatorEquals: 'võrdub',
    filterOperatorStartsWith: 'algab',
    filterOperatorEndsWith: 'lõpeb',
    filterOperatorIs: 'on',
    filterOperatorNot: 'ei ole',
    filterOperatorAfter: 'pärast',
    filterOperatorOnOrAfter: 'on või pärast',
    filterOperatorBefore: 'enne',
    filterOperatorOnOrBefore: 'on või enne',
    filterOperatorIsEmpty: 'on tühi',
    filterOperatorIsNotEmpty: 'ei ole tühi',
    filterOperatorIsAnyOf: 'üks järgmistest',

    // Filter values text
    filterValueAny: 'iga',
    filterValueTrue: 'tõene',
    filterValueFalse: 'väär',

    // Column menu text
    columnMenuLabel: 'Menüü',
    columnMenuShowColumns: 'Näita veerge',
    columnMenuManageColumns: 'Halda veerge',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Peida veerg',
    columnMenuUnsort: 'Eemalda järjestus',
    columnMenuSortAsc: 'Järjesta kasvavalt',
    columnMenuSortDesc: 'Järjesta kahanevalt',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} active filters` : `${count} active filter`,
    columnHeaderFiltersLabel: 'Show filters',
    columnHeaderSortIconLabel: 'Sort',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
        ? `${count.toLocaleString()} rida valitud`
        : `${count.toLocaleString()} rida valitud`,

    // Total row amount footer text
    footerTotalRows: 'Ridu kokku:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',
    checkboxSelectionSelectAllRows: 'Vali kõik',
    checkboxSelectionUnselectAllRows: 'Tühista kõik',
    checkboxSelectionSelectRow: 'Vali rida',
    checkboxSelectionUnselectRow: 'Tühista rea valik',

    // Boolean cell text
    booleanCellTrueLabel: 'jah',
    booleanCellFalseLabel: 'ei',

    // Actions cell more text
    actionsCellMore: 'veel',

    // Column pinning text
    pinToLeft: 'Kinnita vasakule',
    pinToRight: 'Kinnita paremale',
    unpin: 'Eemalda kinnitus',

    // Tree Data
    treeDataGroupingHeaderName: 'Grupeeri',
    treeDataExpand: 'näita lapsed',
    treeDataCollapse: 'peida lapsed',

    // Grouping columns
    groupingColumnHeaderName: 'Gruppeerimine',
    groupColumn: (name) => `Grupeeri ${name} järgi`,
    unGroupColumn: (name) => `Ära grupeeri ${name} järgi`,

    // Master/detail
    detailPanelToggle: 'Lülita detailpaneel sisse/välja',
    expandDetailPanel: 'Laienda',
    collapseDetailPanel: 'Võta kokku',

    // Used core components translation keys
    MuiTablePagination: {},

    // Row reordering text
    rowReorderingHeaderName: 'Ridade ümberjärjestamine',

    // Aggregation
    aggregationMenuItemHeader: 'Agregeeri',
    aggregationFunctionLabelSum: 'summa',
    aggregationFunctionLabelAvg: 'keskmine',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'suurus',
}

export default etGridLocale