import { ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

export const accounts_report_sort_orders = [
    {value: 'entry_order', label: 'entry_order'},
    {value: 'order_by_date', label: 'order_by_date'},
];

export const paramTypes: ParamTypesDescriptors = {
    'period_start': {type: 'date'},
    'period_end': {type: 'date'},
    'article_id': {type: 'select', selectorRd: 'articles', title: 'article_id'},
    'wh_id': {type: 'select', selectorRd: 'warehouses', title: 'wh_id'},
    'obj_ids': {type: 'select', selectorRd: 'objects', title: 'objects'},
};
