import { APPCENTRAL_URL, isDevMode } from "../configs/appConfig"
import { getAuthToken } from "../redux/auth/authSlice"

const URL_DATA = APPCENTRAL_URL + '/?e=getcompanydata&q=';
const URL_NAME = APPCENTRAL_URL + '/?e=getcompanyname&q=';

/**
 * @brief Get company data from Estonian Business Register via Intellisoft's API
 * @param name company name
 * @returns object containing company data
 */
export async function getCompanyData(name: string) : Promise<any> {
    const token = await getAuthToken();
    const resp = await fetch(
        URL_DATA + encodeURIComponent(name),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    );
    const data = await resp.json();
    if(isDevMode) console.log(data);
    return data;
}

/**
 * @brief Get company names for given partial from Estonian Business Register via Intellisoft's API
 * @param partial partial company name
 * @returns array of existing company names including the partial name
 */
export async function getCompanyNames(partial: string) : Promise<any> {
    const token = await getAuthToken();
    const resp = await fetch(
        URL_NAME + encodeURIComponent(partial),
        {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        }
    );
    const list = await resp.text();
    const data = list.split('\n').filter((s: string) => s.length > 0 && s !== '<<<Midagi ei leitud>>>');
    if(isDevMode) console.log('Loaded company names for partial ', partial, ' is: ', data);
    return data;
}