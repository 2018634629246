import React from "react";
import { ReportDescriptor, ReportOutputKind } from "../../../../app/types/ReportDescriptor";
import { ApplicationUnit } from "../../../regs";
import { ParamTypesDescriptors, ReportDataDescriptor, ReportParams, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";
import { DocGridColumn } from "../../../../app/types";
import { getFromCurrentDB } from "../../../api";
import { store } from "../../../../redux/store";

const Form = React.lazy(() => import('./EEFRDataReportReportForm'));

export const eefr_data_sections = ['EE0301010', 'EE0301020', 'EE0302010'];

const paramTypes: ParamTypesDescriptors = {
    'period_start': { type: 'date' },
    'period_end': { type: 'date' },
    'sections': { type: 'text' }
};

class EEFRDataReportRD extends ReportDescriptor {

    reportEndpoint: string = 'eefr_data';
    name: ApplicationUnit = 'eefr_data';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public getOutputKinds(): ReportOutputKind[] {
        return ['json', 'table'];
    }

    public processTabularData(data: any): any {
        const ret: any[] = [];
        let en: string = '';

        console.log('D: ', data);

        data.report.datasets.forEach((ds: any) => {
            en = ds.entryNumber;
            ds.entryDetail.forEach((row: any) => {
                ret.push({
                    ...row,
                    entry_number: en,
                    account_main_id: row.accountMainID,
                    d: row.debitCreditCode === 'D' ? row.amount : undefined,
                    c: row.debitCreditCode === 'C' ? row.amount : undefined,
                    subaccounts: !!row.accountSub ? Object.keys(row.accountSub).map((k: string) => k + ': ' + row.accountSub[k]).join(', ') : '',
                    account_main_desc: data.classifiers[row.accountMainID]
                });
            });
        });

        console.log('X: ', ret);

        return ret;
    }

    public getTabularReportColumns(params: ReportParams): DocGridColumn[] {
        return [
            { name: 'account_main_id', width: 140, label: 'account_main_id' },
            { name: 'account_main_desc', width: 300, label: 'account_main_desc' },
            { name: 'd', type: 'decimal', width: 100, align: 'right', label: 'debit' },
            { name: 'c', type: 'decimal', width: 100, align: 'right', label: 'credit' },
            { name: 'subaccounts', width: 400, label: 'subaccounts' },
        ];
    }

    public async prepareReportData(reportParams: ReportParams, paramTypes: ParamTypesDescriptors): Promise<TabularReportDataDescriptor> {
        const ret = await super.prepareReportData(reportParams, paramTypes);
        const state = store.getState();
        const classifiers = await getFromCurrentDB(state, 'eefr_classifiers', {'_fetch': 'all'});
        const cls : {[key: string]:string} = {};
        classifiers.data.forEach((c: any) => cls[c.code] = c.name);
        return {
            ...ret,
            output: {
                ...ret.output,
                classifiers: cls
            }
        };
    }


    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {
        const reportParams = { ...this.getReportParams(params) };
        return await this.prepareReportData(reportParams, paramTypes);
    }

    public getReportFormModule = async (reportData: ReportDataDescriptor) =>
        await import('./EEFRReportPrintForm');
}

export const eefr_data = new EEFRDataReportRD();

