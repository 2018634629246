import React from "react";
import { DocGridColumn } from "../../../../app/types";
import { useIntl } from "react-intl";
import { INTL_DATEFORMAT, WAYBILL_TYPE } from "../../../../app/const";
import { ApplicationUnit } from "../../../regs";
import { WaybillsRD } from "../WaybillsRD";
import WhAggCostPanel from "../WhAggCostPanel";
import WhInitsFilterForm from "./WhInitsFilterForm";
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import { RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";
import * as Yup from 'yup';
import * as validators from '../../../../app/validationUtils';

const Edit = React.lazy(() => import('./WhInitsEdit'));

class WhInitsRD extends WaybillsRD {

    // properties specific to this registry
    name: ApplicationUnit = 'wh_inits'

    gridEndpoint = 'wh_inits'
    selector = {
        endpoint: 'wh_inits',   //TODO special endpoint
        idCol: 'id',
        captionCol: 'wh_name',
        textCol: 'date',
        memoCol: 'memo'
    }

    defaultDocument = {
        type_id: WAYBILL_TYPE.INIT,
        nrprefix: '',
        nrsuffix: '',
        src_wh_id: 1,
        dst_wh_id: 1,
        total_cost: 0,
        collections: {
            waybillrows: [],
            waybillrowobjects: [],
        }
    }

    public isPrintable(): boolean {
        return false;
    }

    public async afterLoad(doc: any) { 
        return await super.afterLoad({
            ...this.defaultDocument,
            ...doc,
            dst_wh_id: doc.id ? doc.dst_wh_id : this.get_def_wh_id(doc),
        });
    }

    getTitle = (doc: any) => {
        const intl = useIntl()
        return doc ? intl.formatDate(doc.date, INTL_DATEFORMAT) : '';
    }

    getDetailForm = (docPath: string) => <Edit docPath={docPath} />

    public getGridFooterAggPanel(agg: any): JSX.Element | null {
        return <WhAggCostPanel agg={agg} />
    }

    /**
     * @brief check and set wh init doc specific fields
     */
    protected check_type_specific = (doc: any) => {
        return {
            ...doc,
            nr: doc.dst_wh_id
        };
    }

    protected get_type_id = () : number => WAYBILL_TYPE.INIT;

    regFilterTranslations: RegFilterTranslations = {
        doc_date_from: { field: 'doc_date', operator: '>=' },
        doc_date_until: { field: 'doc_date', operator: '<=' },
        total_from: { field: 'total_cost', operator: '>=', type: 'decimal' },
        total_until: { field: 'total_cost', operator: '<=', type: 'decimal' },
        dst_wh_id: { field: 'wh_id', operator: '=', type: 'selected_id_int' },
        article_id: { field: 'article_id', operator: '=', type: 'selected_id_int' },
    }

    public getFilterForm(docPath: string): JSX.Element | null {
        return <WhInitsFilterForm docPath={docPath} />
    }

    columns: DocGridColumn[] = [
        { name: 'doc_date', type: 'date', width: 100, label: 'date' },
        { name: 'wh_code', type: 'string', width: 30 },
        { name: 'wh_name', type: 'string', width: 120 },
        { name: 'total_cost', type: 'decimal', width: 100, align: 'right' },
        { name: 'memo', type: 'string', width: 200 },
        { name: 'summaryrows', type: 'string', width: 200 },
        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]

    public getValidationSchema(): Yup.ObjectSchema<any> | undefined {
        return Yup.object().shape({
            date: validators.date_required(),
            src_wh_id: validators.integer_required_positive(),
            dst_wh_id: Yup.number().integer().min(2, 'wh_init_warehouse_must_be_set').required('Required'),
            collections: Yup.object().shape({
                waybillrows: Yup.array().of(
                    Yup.object().shape({
                        article_id: validators.integer_required_positive(),
                        quantity: validators.number_required_positive() //Yup.number().min(0),
                    })
                )
            })
        });
    }
}

export const wh_inits = new WhInitsRD()