import { ParamTypesDescriptors } from "../../../../app/types/ApplicationUnitDescriptor";

export const accounts_report_sort_orders = [
    {value: 'entry_order', label: 'entry_order'},
    {value: 'order_by_date', label: 'order_by_date'},
];

export const report_type = [
    {value: 1, label: 'customers'},
    {value: 0, label: 'suppliers'},
];

export const paramTypes: ParamTypesDescriptors = {
    'report_date': {type: 'date', title: 'date'},
    'filter_company_id': {type: 'select', selectorRd: 'companies', title: 'company_id'},
    'filter_overdue': {type: 'checkbox', title: 'filter_overdue'},
    'filter_overdue_days': {type: 'number', title: 'overdue_days'},
    'filter_company_group_id': {type: 'select', selectorRd: 'companygroups', title: 'company_group_id'},
    'currency_id': {type: 'select', selectorRd: 'currencies', title: 'currency_id'},
    'obj_id': {type: 'select', selectorRd: 'objects', title: 'obj_id'},
};
