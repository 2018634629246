import React from 'react'
import { ApplicationUnit } from '../../profit/regs'
import {RegistryDescriptor} from './RegistryDescriptor'

const SimpleCodedNamedDetailForm = React.lazy(() => import('../../ui/components/SimpleCodedNamedDetailForm'))

export class SimpleCodedNamedRD extends RegistryDescriptor {

    //constructor with name argument
    constructor(name: ApplicationUnit) {
        super()
        this.name = name
        this.gridEndpoint = name as string
        this.docEndpoint = name as string
        this.selector = {
            endpoint: name as string,
            idCol: 'id',
            captionCol: 'code',
            textCol: 'name',
            queryParams: {_orderBy: 'code'}
        }
        this.columns =  [
            {name: 'code'},
            {name: 'name'},
        ]
    }

    getDetailForm = (docPath: string) =>  <SimpleCodedNamedDetailForm docPath={docPath} />
}