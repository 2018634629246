import React from 'react';
import { SHARED_SETUP_KEYS } from '../../../../../app/const';
import { splitDocPath } from '../../../../../redux/docs/utils';
import SelectEdit from '../../../../../ui/components/edit/SelectEdit';
import { ReportDataDescriptor } from '../../../../../app/types/ApplicationUnitDescriptor';

// interface FormComponents {
//     [key: string]: {
//         company?: string | string[],
//         formFile: string
//         // currency?: string
//     }
// }

export interface WhPrintFormDataDescriptor extends ReportDataDescriptor {
    recipient?: any
    warehouse?: any
    currencies?: any[]
}

// export const salesInvoicePrintForms: FormComponents = {
//     'default': {
//         formFile: 'WhOutcomePrintFormDefault'
//     },
//     'artful': {
//         formFile: 'SalesInvoicePrintFormArtful',
//         company: ['artful', 'artfultest', 'adt_local']
//     },
// };

// const filterMyCompanyForms = (company: string) =>
//     Object.keys(salesInvoicePrintForms)
//         .filter(key => {
//             const f = salesInvoicePrintForms[key];
//             return !f.company || f.company === company || (f.company.length && f.company.includes(company));
//         });

// export const getInvoicePrintFormsSelectorItems = (company: string) =>
//     filterMyCompanyForms(company).map(key => ({ value: key, label: key }));

// export const PrintFormSelector = (params: { docPath: string, field: string }) => {
//     const dpp = splitDocPath(params.docPath);
//     const forms = getInvoicePrintFormsSelectorItems(dpp.dbName);

//     if (forms && forms.length > 1)
//         return <SelectEdit
//             {...params}
//             items={getInvoicePrintFormsSelectorItems(dpp.dbName)}
//             translateItems={false}
//         />
//     else
//         return null;
// }

export const getWhOutcomePrintForm = async (reportData: WhPrintFormDataDescriptor) => {
    console.log('returning print form');
    return await import('./WhOutcomePrintFormDefault')
    // // TODO report form from doc
    // const form = 'default';

    // const FormComponent = (
    //     salesInvoicePrintForms[form]
    //     || salesInvoicePrintForms['default']
    // ).formFile;
    // return await import(`./${FormComponent}`);
}
