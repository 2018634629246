import { RegistryDescriptor } from "./RegistryDescriptor";
import { DocGridColumn } from "./DocGridColumn";
import { ValueType } from "./types";

export {RegistryDescriptor, type DocGridColumn, type ValueType}

export const FIELD_SEL = '__sel'

export type TextEditType = 'string' | 'number' | 'int' | 'date'

export type LoadableItemStatus = 'invalid' | 'loading' | 'idle' | 'error'

export type TextAlignOption = 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'

export type KeyValueList = {
    [key: string]: string
}

export interface LoginForm {
    api: string
    company: string
    login: string
    password: string
    savePassword: boolean
}

export interface InvListItem {
    id: number
    date: string
    warehouseName: string
    groupName: string
    objName: string
    locked: number
    status: string
    creator: string
    created_datetime: string
    modifier: string
    modified_datetime: string
    attsCnt: number
}

export interface InvArtEntry {
    id: number
    code: string
    name: string
    variation: string
    unit: string
    expectedQuantity: number
    expectedCost: number
    actualQuantity: number
    actualCost: number
    state: string
    processed?: boolean
    query?: string
    invId: number
}

export interface ArticleSelectionItem {
    id: number
    code: string
    name: string
}

export type EditFlavor = 'normal' | 'grid'

export type DatabaseStatus = 'waiting' | 'creating' | 'ready' | 'error' | 'archiving' | 'archived' | 'updating';

export interface DatabaseDescriptor {
    caption: string,
    api: string,
    uri: string,
    created_dt?: string,
    ownership?: string,
    status?: DatabaseStatus
}

export interface DocPathParams {
    docPath: string
}

export interface ProfitUser {
    id: number
    uid: string
    email: string
    name: string
    billing_total?: number
}
