import React, { Suspense } from "react";
import { DocGridColumn } from "../../../../app/types";
import { FormattedMessage, useIntl } from "react-intl";
import { INTL_DATEFORMAT, SUM_FORMAT, WAYBILL_TYPE } from "../../../../app/const";
import { ApplicationUnit } from "../../../regs";
import { WaybillsRD } from "../WaybillsRD";
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import { RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";
import * as Yup from 'yup';
import * as validators from '../../../../app/validationUtils';
import { WhPrintFormDataDescriptor, getWhOutcomePrintForm } from "./print";
import { store } from "../../../../redux/store";
import { getFromCurrentDB, getRecordFromCurrentDB } from "../../../api";
import { languages } from "../../../../i18n/messages";
import { warehouse } from "../../../settings/general/WarehouseSetup";

const EditForm = React.lazy(() => import('./WhOutcomesEdit'));
const AggPanel = React.lazy(() => import('../WhAggInOutPanel'));
const FilterForm = React.lazy(() => import('./WhOutcomesFilterForm'));

class WhIncomesRD extends WaybillsRD {

    // properties specific to this registry
    name: ApplicationUnit = 'wh_outcomes'

    gridEndpoint = 'wh_outcomes'
    selector = {
        endpoint: 'wh_outcomes',   //TODO special endpoint
        idCol: 'id',
        captionCol: 'd_caption',
        textCol: 'date',
        memoCol: 'memo'
    }

    defaultDocument = {
        type_id: WAYBILL_TYPE.OUTCOME,
        nrprefix: '',
        nrsuffix: '',
        src_wh_id: 1,
        dst_wh_id: 1,
        collections: {
            waybillrows: [],
            waybillrowobjects: [],
        }
    }

    getTitle = (doc: any) => {
        const intl = useIntl()
        return doc ? (!!doc.nr ? doc.nrprefix + doc.nr + doc.nrsuffix : '') + ' : ' + intl.formatDate(doc.date, INTL_DATEFORMAT) : ''
    }

    public async afterLoad(doc: any) {

        const def_wh_id = this.get_def_wh_id(doc);

        const enm = !doc.id && !!def_wh_id && !!doc.date
            ? await this.fetch_doc_enumeration({
                date: doc.date,
                doc_type_id: WAYBILL_TYPE.OUTCOME,
                src_wh_id: def_wh_id,
                dst_wh_id: 1,
            })
            : {};

        const ret = {
            ...this.defaultDocument,
            ...doc,
            ...enm,
            src_wh_id: doc.id ? doc.src_wh_id : def_wh_id,
        };

        return await super.afterLoad(ret); // this.calc_doc_totals(ret);
    }

    // Forms
    public getDetailForm = (docPath: string) => <EditForm docPath={docPath} />
    public getGridFooterAggPanel = (agg: any): JSX.Element | null => <AggPanel agg={agg} />
    public getFilterForm = (docPath: string): JSX.Element | null => <FilterForm docPath={docPath} />

    protected get_type_id = (): number => WAYBILL_TYPE.OUTCOME;

    regFilterTranslations: RegFilterTranslations = {
        doc_date_from: { field: 'doc_date', operator: '>=' },
        doc_date_until: { field: 'doc_date', operator: '<=' },
        total_cost_from: { field: 'total_cost', operator: '>=', type: 'decimal' },
        total_cost_until: { field: 'total_cost', operator: '<=', type: 'decimal' },
        total_price_from: { field: 'total_price', operator: '>=', type: 'decimal' },
        total_price_until: { field: 'total_price', operator: '<=', type: 'decimal' },
        src_wh_id: { field: 'wh_id', operator: '=', type: 'selected_id_int' },
        customer_id: { field: 'customer_id', operator: '=', type: 'selected_id_int' },
        article_id: { field: 'article_id', operator: '=', type: 'selected_id_int' },
        obj_id: { field: 'obj_id', operator: '=', type: 'selected_id_int' },
    }

    columns: DocGridColumn[] = [
        { name: 'locked', type: 'locked_icon', label: 'locked_icon', width: 30 },
        { name: 'd_caption', type: 'string', width: 80, label: 'nr' },
        { name: 'doc_date', type: 'date', width: 100, label: 'date' },
        { name: 'wh_name', type: 'string', width: 150 },
        { name: 'customer_name', type: 'string', width: 150 },
        { name: 'total_cost', type: 'decimal', width: 100, align: 'right' },
        { name: 'total_price', type: 'decimal', width: 100, align: 'right' },
        { name: 'summaryobj', type: 'string', width: 100 },
        { name: 'salesinvoice_nr', type: 'string', width: 100, label: 'invoice_nr' },
        { name: 'doc', type: 'string', width: 200 },
        { name: 'memo', type: 'string', width: 200 },
        { name: 'summaryrows', type: 'string', width: 200 },
        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]

    public isLockable = (): boolean => true;

    public getValidationSchema(): Yup.ObjectSchema<any> | undefined {
        return Yup.object().shape({
            date: validators.date_required(),
            src_wh_id: validators.integer_required_positive(),
            dst_wh_id: validators.integer_required_positive(),
            recipient_id: validators.integer_required_positive(),
            collections: Yup.object().shape({
                waybillrows: Yup.array().of(
                    Yup.object().shape({
                        article_id: validators.integer_required_positive(),
                        quantity: validators.number_required_positive(),
                        price: validators.number_required_positive()
                        // cost: validators.number_required_positive()
                    })
                ).nullable().required() // Add nullable() and required() to allow for dynamic array indexing
            }).nullable().required()
        });
    }

    public async getReportFormModule(reportData: WhPrintFormDataDescriptor) {
        return await getWhOutcomePrintForm(reportData);
    }


    public async loadReportData(params: any): Promise<WhPrintFormDataDescriptor> {
        console.log('loadReportData', params)
        const state = store.getState();
        // const db = state.databases.currentDatabase!.uri;
        const recipient = await getRecordFromCurrentDB(state, 'companies/' + params.recipient_id);
        const units = (await getFromCurrentDB(state, 'units/')).data;
        const currencies = (await getFromCurrentDB(state, 'currencies/')).data;

        console.log('loaded', currencies);

        // email recipient
        // const email_to = recipient.email;
        // const report_title = ((params.title || '') + ' ' + this.getTitle(params)).trim();

        // TODO instead of loading articles one by one, load them all at once and then filter
        // can be done in a single query, can load only necessary records based on row.article_id

        const doc = this.cleanupChildren(params);

        const colPmc = doc.collections.waybillrows.map( async (r: any) => {

            const art = await getRecordFromCurrentDB(state, 'articles_selector/' + r.article_id);

            return {
                ...r,
                total: r.quantity * r.price,
                article_code: art.code,
                article_name: art.name,
                unit_code: units.find((u: any) => u.id === r.unit_id)?.code,
            }
        });


        const col = await Promise.all(colPmc);

        const ret = {
            locale: params.language || recipient.language || languages[0], // TODO buyer language also?
            doc: { ...doc, collections: { waybillrows: col } },
            warehouse: await getRecordFromCurrentDB(state, 'warehouses/' + params.src_wh_id),
            // currency: await getRecordFromCurrentDB(state, 'currencies/' + params.currency_id),
            // modifier: await getRecordFromCurrentDB(state, 'users_grid/' + params.modifier_id),
            recipient: recipient,
            currencies: currencies,
            // buyer_country: await getRecordFromCurrentDB(state, 'countries/' + buyer.legal_address_country_id),
            // payer: payer,
            // bank_accounts: (await getFromCurrentDB(state, 'printable_bank_accounts')).data,
            // buyer_group: await getRecordFromCurrentDB(state, 'companygroups/' + buyer.group_id),
            // email_to: email_to,
            // report_title: report_title
        }

        console.log('returning report data', ret);
        return ret;
    }

    protected exec_and_reload_actions : {[key: string]: string} = {
        '#custom_whdoc_add_salesinvoice': 'gin_to_salesinvoice',
    }

}

export const wh_outcomes = new WhIncomesRD()