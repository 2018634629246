import * as Yup from 'yup';

export const date_required = () => Yup.string()
  .required('Date is required')
  .test('is-valid-date', 'Invalid date format', (value) => {
    // You can customize the date format validation here
    // For example, check if it matches 'yyyy-mm-dd' pattern
    return /\d{4}-\d{2}-\d{2}/.test(value)
  })

export const due_period = () => Yup.number().integer().min(0).required('Required')


export const integer_required_positive = () => Yup.number().integer().min(1).required('Required')


export const number = () => Yup.number()
export const number_required = () => Yup.number().required('Required')
export const number_required_positive = () => Yup.number().min(1, 'Required').required('Required')

export const currency_rate = () => Yup.number().required('Required').notOneOf([0], 'Currency rate must not be 0')

export const non_negative_decimal2 = () => Yup.number()
    .min(0)
    .test('max-decimals', 'This field must be non negative and can have at most 2 decimal places', (value) =>
        value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
    )