import React from "react";
import { RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from "../../../regs";

const AccountForm = React.lazy(() => import('./AccountForm'));

class AccountsRD extends RegistryDescriptor {
    gridEndpoint = 'accounts';
    docEndpoint = 'accounts';
    selector = {
        endpoint: 'accounts',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
        queryParams: {_orderBy: 'code'},
    }

    name: ApplicationUnit = 'accounts'

    columns = [
        {name: 'code'},
        {name: 'name'}
    ];
    
    public getTitle(doc: any): string {
        return doc ? (doc.code || '') + (doc.name ? ' : ' + doc.name : '') : '';
    }
    
    public getDetailForm = (docPath: string) =>  <AccountForm docPath={docPath} />
}

class DetailAccountsSelectorRD extends RegistryDescriptor {
    selector = {
        endpoint: 'accounts',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
        queryParams: {
            _orderBy: 'code', 
            _filter: JSON.stringify([{field: 'type_id', operator: '=', value: '0'}])
        },
    }

    name: ApplicationUnit = 'detail_accounts_selector'
}

export const accounts = new AccountsRD();
export const detail_accounts_selector = new DetailAccountsSelectorRD();