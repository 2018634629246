import React from 'react'
import { SimpleCodedNamedWithMemoRD } from '../../../../app/types/SimpleCodedNamedWithMemoRD';

const UnitEdit = React.lazy(() => import('./CaGroupEdit'))

class CaGroupsRD extends SimpleCodedNamedWithMemoRD {

    defaultDocument = {}
    
    getDetailForm = (docPath: string) =>  <UnitEdit docPath={docPath} />

    constructor() {
        super('ca_groups')
    }
}

export const ca_groups = new CaGroupsRD()